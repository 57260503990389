import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Box, Checkbox, FormControlLabel, Grid, Pagination, Snackbar, Stack, TextField } from "@mui/material";
import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { listAllNftsWithMetadata } from "../api-clients/hedera-mirror-node-api-helper";
import { CollectionHoldersButton } from "../components/collections/collection-holders";
import { NftFilter } from "../components/nfts/nft-filter";
import { NftSquare } from "../components/nfts/nft-square";
import { actions } from "../store";
import { wellKnownAccounts } from "../utils";
import { alpha, useTheme } from "@mui/material/styles";
export var Collection = function () {
    var _a = useState(new Map()), properties = _a[0], setProperties = _a[1];
    var _b = useState([]), nfts = _b[0], setNfts = _b[1];
    var _c = useState(false), paperhandsOnly = _c[0], setPaperhandsOnly = _c[1];
    var _d = useState(''), err = _d[0], setErr = _d[1];
    var _e = useState(new Map()), selectedAttributes = _e[0], setSelectedAttributes = _e[1];
    var _f = useState(''), searchSerial = _f[0], setSearchSerial = _f[1];
    var dispatch = useDispatch();
    var params = useParams();
    var id = params.id ? params.id : "0.0.994239";
    var idRef = useRef(id);
    var theme = useTheme();
    useEffect(function () {
        idRef.current = id;
    }, [id]);
    useEffect(function () {
        setNfts([]);
        dispatch(actions.page.setIsLoading(true));
        listAllNftsWithMetadata(id, Infinity, function (_, allNfts) {
            if (idRef.current === id) {
                setNfts(allNfts);
            }
        }).then(function (allNfts) {
            if (allNfts.length === 0) {
                setErr("Could not find NFTs for token id: ".concat(id));
            }
        }).catch(function (err) {
            if (idRef.current === id) {
                setErr(typeof err === 'string' ? err : JSON.stringify(err));
            }
        }).finally(function () {
            if (idRef.current === id) {
                dispatch(actions.page.setIsLoading(false));
            }
        });
    }, [id, idRef, dispatch]);
    useEffect(function () {
        var _a;
        var propMap = new Map();
        for (var _i = 0, nfts_1 = nfts; _i < nfts_1.length; _i++) {
            var nft = nfts_1[_i];
            if ((_a = nft === null || nft === void 0 ? void 0 : nft.metadataObj) === null || _a === void 0 ? void 0 : _a.attributes) {
                for (var _b = 0, _c = nft.metadataObj.attributes; _b < _c.length; _b++) {
                    var attribute = _c[_b];
                    if (propMap.has(attribute.trait_type)) {
                        var propValues = propMap.get(attribute.trait_type);
                        if (!propValues.includes(attribute.value)) {
                            propValues.push(attribute.value);
                        }
                    }
                    else {
                        propMap.set(attribute.trait_type, [attribute.value]);
                    }
                }
            }
        }
        setProperties(propMap);
    }, [nfts]);
    var nftsFiltered = useMemo(function () {
        var anyFiltersSelected = Array.from(selectedAttributes).some(function (o) { return o[1].length > 0; });
        var nftsFiltered = nfts.filter(function (o) {
            var _a;
            var shouldShowNft = true;
            if (anyFiltersSelected) {
                var _loop_1 = function (selectedAttribute) {
                    var attributeTraitType = selectedAttribute[0];
                    var attributeTraitValues = selectedAttribute[1];
                    if (attributeTraitValues.length === 0) {
                        return "continue";
                    }
                    var hasAttr = (_a = o.metadataObj) === null || _a === void 0 ? void 0 : _a.attributes.some(function (attr) { return attr.trait_type === attributeTraitType && attributeTraitValues.includes(attr.value); });
                    if (!hasAttr) {
                        shouldShowNft = false;
                        return "break";
                    }
                };
                for (var _i = 0, _b = Array.from(selectedAttributes); _i < _b.length; _i++) {
                    var selectedAttribute = _b[_i];
                    var state_1 = _loop_1(selectedAttribute);
                    if (state_1 === "break")
                        break;
                }
            }
            return shouldShowNft;
        });
        if (paperhandsOnly) {
            nftsFiltered = nftsFiltered.filter(function (o) { return o.spender === wellKnownAccounts["Zuse Secondary"]; });
        }
        if (searchSerial) {
            nftsFiltered = nftsFiltered.filter(function (nft) { return String(nft.serial_number).includes(searchSerial); });
        }
        return nftsFiltered;
    }, [nfts, selectedAttributes, paperhandsOnly, searchSerial]);
    var itemsPerPage = 12;
    var pages = Math.ceil(nftsFiltered.length / itemsPerPage);
    var _g = useState(1), currentPage = _g[0], setCurrentPage = _g[1];
    var _h = useState('1'), currentPageInput = _h[0], setCurrentPageInput = _h[1];
    var setCurrentPageDebounced = useMemo(function () {
        return _.debounce(function (pageNum) { return setCurrentPage(pageNum); }, 250, {
            leading: false,
            trailing: true,
        });
    }, [setCurrentPage]);
    useEffect(function () {
        var num = Number.parseInt(currentPageInput);
        if (Number.isNaN(num)) {
            return;
        }
        if (num > pages) {
            setCurrentPageDebounced(pages);
        }
        else if (num < 1) {
            setCurrentPageDebounced(1);
        }
        else if (!num) {
            setCurrentPageDebounced(1);
        }
        else {
            setCurrentPageDebounced(num);
        }
    }, [pages, currentPageInput, setCurrentPageDebounced]);
    var startIndex = itemsPerPage * (currentPage - 1);
    var endIndex = startIndex + itemsPerPage;
    var serialSearcher = function (event) {
        var value = event.target.value;
        setSearchSerial(value);
    };
    return (_jsxs(Stack, { spacing: 1, children: [_jsx(Snackbar, { open: err !== '', onClose: function () { return setErr(''); }, children: _jsx(Alert, { severity: "error", onClose: function () { return setErr(''); }, children: err }) }), _jsx(Box, { display: "flex", children: _jsx(TextField, { placeholder: "Serial Number", type: "text", autoCapitalize: "off", autoComplete: "off", autoCorrect: "off", value: searchSerial, onChange: serialSearcher, sx: {
                        borderRadius: "4px",
                        backgroundColor: alpha(theme.palette.common.white, 0.15),
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.common.white, 0.25),
                        },
                        '& fieldset': {
                            borderWidth: 0,
                        },
                    } }) }), _jsxs(Box, { display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "1rem", children: [_jsx(CollectionHoldersButton, { nfts: nfts, tokenId: id }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: paperhandsOnly, onChange: function (e) {
                                setPaperhandsOnly(e.target.checked);
                            } }), label: "Paperhands Only" })] }), _jsx(NftFilter, { properties: properties, onChange: function (filters) { return setSelectedAttributes(filters); } }), _jsx(Box, { children: _jsx(Grid, { container: true, spacing: 1, children: nftsFiltered.slice(startIndex, endIndex).map(function (o) {
                        return (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 2, children: _jsx(NftSquare, { tokenId: o.token_id, serialNumber: o.serial_number }) }, "".concat(o.token_id, ":").concat(o.serial_number)));
                    }) }) }), _jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", children: [_jsx(Pagination, { count: pages, page: currentPage, color: "primary", onChange: function (_, page) {
                            setCurrentPage(page);
                            setCurrentPageInput("".concat(page));
                        } }), _jsx(TextField, { type: "number", value: currentPageInput, onChange: function (e) {
                            setCurrentPageInput(e.target.value);
                        }, inputProps: {
                            sx: {
                                paddingTop: '0.5rem',
                                paddingBottom: '0.5rem',
                            },
                        }, sx: {
                            width: '5rem',
                        } })] })] }));
};
