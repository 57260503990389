var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, Link, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { DownloadCSV } from "../utilities/download-csv";
import RefreshIcon from '@mui/icons-material/Refresh';
;
var RandomNFTOwner = function (_a) {
    var nftOwners = _a.nftOwners;
    var _b = useState(-1), winningAccountIdIndex = _b[0], setWinningAccountIdIndex = _b[1];
    var _c = useState(5), nftsPerEntry = _c[0], setNftsPerEntry = _c[1];
    useEffect(function () {
        setWinningAccountIdIndex(-1);
    }, [nftsPerEntry]);
    var entries = useMemo(function () { return nftOwners.map(function (o) { return ({
        accountId: o.accountId,
        entries: nftsPerEntry > 0 ? Math.floor(o.count / nftsPerEntry) : 0
    }); }); }, [nftOwners, nftsPerEntry]);
    var choices = useMemo(function () {
        return entries.flatMap(function (o) { return new Array(o.entries).fill(o.accountId); });
    }, [entries]);
    var setToRandom = useMemo(function () {
        return function () {
            var rand = Math.floor(Math.random() * choices.length);
            setWinningAccountIdIndex(rand);
        };
    }, [choices]);
    var winningAccountId = winningAccountIdIndex === -1 ? "None" : choices[winningAccountIdIndex];
    var winningAccountsEntries = 0;
    var foundEntry = entries.find(function (o) { return o.accountId === winningAccountId; });
    if (foundEntry) {
        winningAccountsEntries = foundEntry.entries;
    }
    return (_jsxs(Box, { children: [_jsx(Typography, { variant: "h6", children: "Draw Winner" }), _jsx(TextField, { label: "# NFTs per entry", type: "number", value: nftsPerEntry, onChange: function (e) {
                    setNftsPerEntry(Number.parseInt(e.target.value));
                }, InputProps: {
                    endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { edge: "end", onClick: function () {
                                setToRandom();
                            }, children: _jsx(RefreshIcon, {}) }) }))
                } }), _jsxs(Typography, { children: ["Winner:\u00A0", winningAccountId === "None" ? ("None") : (_jsx(Link, { to: "/account/".concat(winningAccountId), component: RouterLink, children: winningAccountId })), " [RNG: ".concat(winningAccountIdIndex, "] [").concat(winningAccountsEntries, " of ").concat(choices.length, " entries]")] })] }));
};
var CollectionHoldersModal = function (props) {
    var nfts = props.nfts, tokenId = props.tokenId;
    var _a = useState([]), nftOwners = _a[0], setNftOwners = _a[1];
    var _b = useState(true), includeListed = _b[0], setIncludeListed = _b[1];
    useEffect(function () {
        if (nfts) {
            (function () { return __awaiter(void 0, void 0, void 0, function () {
                var owners, _i, nfts_1, nft, accId, currInfo, nftOwnershipCount;
                return __generator(this, function (_a) {
                    owners = new Map();
                    for (_i = 0, nfts_1 = nfts; _i < nfts_1.length; _i++) {
                        nft = nfts_1[_i];
                        if (!includeListed && nft.spender !== null) {
                            continue;
                        }
                        accId = nft.account_id;
                        if (accId) {
                            if (owners.has(accId)) {
                                currInfo = owners.get(accId);
                                currInfo.count++;
                                currInfo.serialNumbers.push(nft.serial_number);
                                owners.set(accId, currInfo);
                            }
                            else {
                                owners.set(accId, {
                                    count: 1,
                                    serialNumbers: [nft.serial_number]
                                });
                            }
                        }
                    }
                    nftOwnershipCount = Array.from(owners).sort(function (a, b) { return b[1].count - a[1].count; }).map(function (o) { return ({
                        accountId: o[0],
                        count: o[1].count,
                        serialNumbers: o[1].serialNumbers,
                    }); });
                    setNftOwners(nftOwnershipCount);
                    return [2];
                });
            }); })();
        }
        return function () { return setNftOwners([]); };
    }, [nfts, includeListed]);
    return (_jsx(Modal, { open: props.open, onClose: props.onClose, children: _jsxs(Box, { sx: {
                overflow: "scroll",
                maxHeight: "90vh",
                minWidth: "33vw",
                maxWidth: "90vw",
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }, children: [_jsx(Typography, { variant: "h6", textAlign: "center", children: "NFT Hodlers" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: includeListed, onChange: function (e) {
                            setIncludeListed(e.target.checked);
                        } }), label: "Include Listed NFTs" }), _jsx(RandomNFTOwner, { nftOwners: nftOwners }), _jsx(Box, { display: "flex", flexDirection: "row", flexWrap: "wrap", children: _jsx(DownloadCSV, { data: nftOwners, filename: "".concat(tokenId.split('.').pop(), "_hodlers.csv") }) }), _jsx(TableContainer, { component: Paper, children: _jsxs(Table, { "aria-label": "simple table", children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { align: "right", children: "Account Id" }), _jsx(TableCell, { align: "right", children: "#" })] }) }), _jsx(TableBody, { children: nftOwners.map(function (row) {
                                    return (_jsxs(TableRow, { children: [_jsx(TableCell, { align: "right", children: _jsx(Link, { to: "/account/".concat(row.accountId), component: RouterLink, children: row.accountId }) }), _jsx(TableCell, { align: "right", children: row.count })] }, row.accountId));
                                }) })] }) })] }) }));
};
export var CollectionHoldersButton = function (props) {
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1];
    return (_jsxs(_Fragment, { children: [_jsx(Button, { color: "success", variant: "contained", onClick: function () {
                    setIsModalOpen(!isModalOpen);
                }, sx: props.sx, children: "NFT Hodlers" }), _jsx(CollectionHoldersModal, { nfts: props.nfts, tokenId: props.tokenId, open: isModalOpen, onClose: function () {
                    setIsModalOpen(false);
                } })] }));
};
