var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { TokenInfoType } from "../api-clients/hedera-mirror-node-api-client";
import { client } from "../api-clients/hedera-mirror-node-api-helper";
export var IdType;
(function (IdType) {
    IdType["ACCOUNT"] = "account";
    IdType["TOPIC"] = "topic";
    IdType["NFT"] = "nft";
    IdType["NFT_SN"] = "nft_sn";
    IdType["FT"] = "ft";
    IdType["DOMAIN"] = "domain";
    IdType["UNKNOWN"] = "unknown";
})(IdType || (IdType = {}));
export var getIdType = function (id) { return __awaiter(void 0, void 0, Promise, function () {
    var sn, idParts, account, _a, token, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                sn = -1;
                if (id.includes('/')) {
                    idParts = id.split('/');
                    console.log(idParts);
                    id = idParts[0];
                    sn = Number.parseInt(idParts[1]);
                }
                if (Array.from(id).reduce(function (acc, c) { return c === '.' ? ++acc : acc; }, 0) === 1) {
                    return [2, IdType.DOMAIN];
                }
                _c.label = 1;
            case 1:
                _c.trys.push([1, 3, , 4]);
                return [4, client.getAccountByIdOrAliasOrEvmAddress(id, undefined)];
            case 2:
                account = _c.sent();
                if (account.result.account) {
                    return [2, IdType.ACCOUNT];
                }
                return [3, 4];
            case 3:
                _a = _c.sent();
                return [3, 4];
            case 4:
                _c.trys.push([4, 6, , 7]);
                return [4, client.getTokenById(id, undefined)];
            case 5:
                token = _c.sent();
                if (token.result.type === TokenInfoType.FUNGIBLE_COMMON) {
                    return [2, IdType.FT];
                }
                else if (token.result.type === TokenInfoType.NON_FUNGIBLE_UNIQUE) {
                    if (sn === -1) {
                        return [2, IdType.NFT];
                    }
                    else {
                        return [2, IdType.NFT_SN];
                    }
                }
                return [3, 7];
            case 6:
                _b = _c.sent();
                return [3, 7];
            case 7: return [2, IdType.UNKNOWN];
        }
    });
}); };
