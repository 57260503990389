var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { HbarUnit } from "@hashgraph/sdk";
import { Box, Chip, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { TokenInfoType } from "../../api-clients/hedera-mirror-node-api-client";
import { getTokenInfo } from "../../api-clients/hedera-mirror-node-api-helper";
import { getAccountBalances } from "../../services/hedera-client";
export var AccountBalance = function (props) {
    var accountId = props.accountId;
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState([]), fungibleTokens = _b[0], setFungibleTokens = _b[1];
    useEffect(function () {
        if (accountId) {
            setIsLoading(true);
            getAccountBalances(accountId).then(function (balances) { return __awaiter(void 0, void 0, void 0, function () {
                var fungibleTokens, tokenIds, _i, tokenIds_1, tokenId, amount, info;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            fungibleTokens = [
                                {
                                    tokenInfo: "HBAR",
                                    amount: Number.parseFloat(balances.hbars.toString(HbarUnit.Hbar)),
                                }
                            ];
                            if (!balances.tokens) return [3, 5];
                            tokenIds = Array.from(balances.tokens.keys());
                            _i = 0, tokenIds_1 = tokenIds;
                            _a.label = 1;
                        case 1:
                            if (!(_i < tokenIds_1.length)) return [3, 4];
                            tokenId = tokenIds_1[_i];
                            amount = balances.tokens.get(tokenId).toNumber();
                            if (amount === 0) {
                                return [3, 3];
                            }
                            return [4, getTokenInfo(tokenId.toString())];
                        case 2:
                            info = _a.sent();
                            if (info.type === TokenInfoType.FUNGIBLE_COMMON) {
                                fungibleTokens.push({
                                    tokenInfo: info,
                                    amount: amount / Math.pow(10, Number.parseInt(info.decimals)),
                                });
                            }
                            _a.label = 3;
                        case 3:
                            _i++;
                            return [3, 1];
                        case 4:
                            setFungibleTokens(fungibleTokens);
                            _a.label = 5;
                        case 5: return [2];
                    }
                });
            }); }).finally(function () {
                setIsLoading(false);
            });
        }
        else {
            setIsLoading(false);
        }
    }, [accountId]);
    return (_jsxs(_Fragment, { children: [_jsx(Box, { display: "flex", flexDirection: "row", flexWrap: "wrap", children: _jsx(Typography, { variant: "h2", children: "Token Balances" }) }), isLoading ? (_jsx(CircularProgress, {})) : (_jsx(Box, { display: "flex", gap: 0.5, flexWrap: "wrap", children: fungibleTokens.map(function (t) { return (t.tokenInfo === "HBAR" ? (_jsx(Chip, { label: "HBAR: ".concat(t.amount.toLocaleString()) }, "HBAR")) : (_jsx(Chip, { label: "".concat(t.tokenInfo.symbol, ": ").concat(t.amount.toLocaleString()) }, "".concat(t.tokenInfo.token_id)))); }) }))] }));
};
