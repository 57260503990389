import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Box, Grid, Pagination, Typography, TextField } from "@mui/material";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { getFirstNft, getMostRecentNFTs } from "../api-clients/hedera-mirror-node-api-helper";
import { NftSquare } from "../components/nfts/nft-square";
export var Home = function () {
    var _a = useState(new Map()), firstNftByTokenId = _a[0], setFirstNftByTokenId = _a[1];
    var _b = useState([]), nftTokens = _b[0], setNftTokens = _b[1];
    useEffect(function () {
        getMostRecentNFTs().then(function (response) {
            if (response.tokens) {
                setNftTokens(response.tokens);
            }
        });
    }, []);
    useEffect(function () {
        var map = new Map();
        var _loop_1 = function (nft) {
            if (!map.has(nft.token_id)) {
                getFirstNft(nft.token_id).then(function (val) {
                    if (val) {
                        map.set(nft.token_id, val);
                    }
                    setFirstNftByTokenId(new Map(map));
                });
            }
        };
        for (var _i = 0, nftTokens_1 = nftTokens; _i < nftTokens_1.length; _i++) {
            var nft = nftTokens_1[_i];
            _loop_1(nft);
        }
    }, [nftTokens]);
    var nfts = useMemo(function () {
        return nftTokens.map(function (o) { return firstNftByTokenId.get(o.token_id); }).filter(function (o) { return !!o; });
    }, [nftTokens, firstNftByTokenId]);
    var itemsPerPage = 12;
    var pages = Math.ceil(nfts.length / itemsPerPage);
    var _c = useState(1), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = useState('1'), currentPageInput = _d[0], setCurrentPageInput = _d[1];
    var setCurrentPageDebounced = useMemo(function () {
        return _.debounce(function (pageNum) { return setCurrentPage(pageNum); }, 250, {
            leading: false,
            trailing: true,
        });
    }, [setCurrentPage]);
    useEffect(function () {
        var num = Number.parseInt(currentPageInput);
        if (Number.isNaN(num)) {
            return;
        }
        if (num > pages) {
            setCurrentPageDebounced(pages);
        }
        else if (num < 1) {
            setCurrentPageDebounced(1);
        }
        else if (!num) {
            setCurrentPageDebounced(1);
        }
        else {
            setCurrentPageDebounced(num);
        }
    }, [pages, currentPageInput, setCurrentPageDebounced]);
    var startIndex = itemsPerPage * (currentPage - 1);
    var endIndex = startIndex + itemsPerPage;
    return (_jsxs(Stack, { spacing: 1, children: [_jsx(Typography, { variant: "h2", children: "Newest NFTs" }), _jsx(Box, { children: _jsx(Grid, { container: true, spacing: 1, children: nfts.slice(startIndex, endIndex).map(function (o) {
                        return (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 2, children: _jsx(NftSquare, { tokenId: o.token_id, serialNumber: o.serial_number }) }, "".concat(o.token_id, ":").concat(o.serial_number)));
                    }) }) }), _jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", children: [_jsx(Pagination, { count: pages, page: currentPage, color: "primary", onChange: function (_, page) {
                            setCurrentPage(page);
                            setCurrentPageInput("".concat(page));
                        } }), _jsx(TextField, { type: "number", value: currentPageInput, onChange: function (e) {
                            setCurrentPageInput(e.target.value);
                        }, inputProps: {
                            sx: {
                                paddingTop: '0.5rem',
                                paddingBottom: '0.5rem',
                            },
                        }, sx: {
                            width: '5rem',
                        } })] })] }));
};
