import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Chip, Accordion, AccordionSummary, AccordionDetails, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export var NftFilter = function (props) {
    var _a = useState(new Map()), propsSelected = _a[0], setPropsSelected = _a[1];
    useEffect(function () {
        props.onChange(propsSelected);
    }, [props, propsSelected]);
    var traitsSelectedCount = Array.from(propsSelected.values())
        .map(function (o) { return o.length; })
        .reduce(function (acc, curr) { return acc + curr; }, 0);
    return (_jsxs(Accordion, { children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), children: _jsxs(Typography, { variant: "subtitle1", children: ["Traits", traitsSelectedCount ? (_jsxs(_Fragment, { children: ["\u00A0(", traitsSelectedCount, " selected)"] })) : null] }) }), _jsx(AccordionDetails, { children: Array.from(props.properties.entries())
                    .map(function (p) {
                    var attributeName = p[0];
                    var attributeValues = p[1];
                    return (_jsxs(Grid, { container: true, spacing: 0.5, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "subtitle2", children: attributeName }) }), attributeValues
                                .sort(function (a, b) { return a.localeCompare(b); })
                                .map(function (attributeValue) {
                                var _a;
                                return (_jsx(Grid, { item: true, children: _jsx(Chip, { label: attributeValue, variant: ((_a = propsSelected.get(attributeName)) === null || _a === void 0 ? void 0 : _a.includes(attributeValue)) ? 'outlined' : undefined, onClick: function () {
                                            var _a;
                                            var newPropsSelected = new Map(propsSelected.entries());
                                            var ps = new Set((_a = newPropsSelected.get(attributeName)) !== null && _a !== void 0 ? _a : []);
                                            if (!ps.has(attributeValue)) {
                                                ps.add(attributeValue);
                                            }
                                            else {
                                                ps.delete(attributeValue);
                                            }
                                            var newPropValues = Array.from(ps);
                                            newPropsSelected.set(attributeName, newPropValues);
                                            setPropsSelected(newPropsSelected);
                                        } }) }, attributeValue));
                            })] }, attributeName));
                }) })] }));
};
