var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
var themeOptions = {
    typography: {
        h1: {
            fontSize: '3rem',
        },
        h2: {
            fontSize: '2.5rem',
        },
        h3: {
            fontSize: '2rem',
        },
        h4: {
            fontSize: '1.75rem',
        },
        h5: {
            fontSize: '1.5rem',
        },
        h6: {
            fontSize: '1.25rem',
        },
        subtitle1: {
            fontSize: '0.9rem',
        },
        subtitle2: {
            fontSize: '0.8rem',
        },
    },
    palette: {
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
    },
};
var themeLight = createTheme(themeOptions);
var themeDark = createTheme(__assign(__assign({}, themeOptions), { palette: __assign(__assign({}, themeOptions.palette), { mode: 'dark' }) }));
export var ThemeProvider = function (props) {
    var prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    var theme = useMemo(function () {
        if (prefersDarkMode) {
            return themeDark;
        }
        return themeLight;
    }, [prefersDarkMode]);
    return (_jsx(MuiThemeProvider, __assign({}, props, { theme: theme })));
};
