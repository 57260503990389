var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var HederaMirrorNodeAPIClient = (function () {
    function HederaMirrorNodeAPIClient(http) {
        this.jsonParseReviver = undefined;
        this.http = http ? http : window;
        this.baseUrl = "https://mainnet-public.mirrornode.hedera.com";
    }
    HederaMirrorNodeAPIClient.prototype.listAccounts = function (account_balance, account_id, account_publickey, balance, limit, order) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/accounts?";
        if (account_balance === null)
            throw new Error("The parameter 'account_balance' cannot be null.");
        else if (account_balance !== undefined)
            url_ += "account.balance=" + encodeURIComponent("" + account_balance) + "&";
        if (account_id === null)
            throw new Error("The parameter 'account_id' cannot be null.");
        else if (account_id !== undefined)
            url_ += "account.id=" + encodeURIComponent("" + account_id) + "&";
        if (account_publickey === null)
            throw new Error("The parameter 'account_publickey' cannot be null.");
        else if (account_publickey !== undefined)
            url_ += "account.publickey=" + encodeURIComponent("" + account_publickey) + "&";
        if (balance === null)
            throw new Error("The parameter 'balance' cannot be null.");
        else if (balance !== undefined)
            url_ += "balance=" + encodeURIComponent("" + balance) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListAccounts(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListAccounts = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = AccountsResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getAccountByIdOrAliasOrEvmAddress = function (idOrAliasOrEvmAddress, transactiontype) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/accounts/{idOrAliasOrEvmAddress}?";
        if (idOrAliasOrEvmAddress === undefined || idOrAliasOrEvmAddress === null)
            throw new Error("The parameter 'idOrAliasOrEvmAddress' must be defined.");
        url_ = url_.replace("{idOrAliasOrEvmAddress}", encodeURIComponent("" + idOrAliasOrEvmAddress));
        if (transactiontype === null)
            throw new Error("The parameter 'transactiontype' cannot be null.");
        else if (transactiontype !== undefined)
            url_ += "transactiontype=" + encodeURIComponent("" + transactiontype) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetAccountByIdOrAliasOrEvmAddress(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetAccountByIdOrAliasOrEvmAddress = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = AccountBalanceTransactions.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listNftByAccountId = function (idOrAliasOrEvmAddress, token_id, serialnumber, spender_id, limit, order) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/accounts/{idOrAliasOrEvmAddress}/nfts?";
        if (idOrAliasOrEvmAddress === undefined || idOrAliasOrEvmAddress === null)
            throw new Error("The parameter 'idOrAliasOrEvmAddress' must be defined.");
        url_ = url_.replace("{idOrAliasOrEvmAddress}", encodeURIComponent("" + idOrAliasOrEvmAddress));
        if (token_id === null)
            throw new Error("The parameter 'token_id' cannot be null.");
        else if (token_id !== undefined)
            url_ += "token.id=" + encodeURIComponent("" + token_id) + "&";
        if (serialnumber === null)
            throw new Error("The parameter 'serialnumber' cannot be null.");
        else if (serialnumber !== undefined)
            url_ += "serialnumber=" + encodeURIComponent("" + serialnumber) + "&";
        if (spender_id === null)
            throw new Error("The parameter 'spender_id' cannot be null.");
        else if (spender_id !== undefined)
            url_ += "spender.id=" + encodeURIComponent("" + spender_id) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListNftByAccountId(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListNftByAccountId = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = Nfts.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listCryptoAllowancesByAccountId = function (idOrAliasOrEvmAddress, spender_id, limit, order) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/accounts/{idOrAliasOrEvmAddress}/allowances/crypto?";
        if (idOrAliasOrEvmAddress === undefined || idOrAliasOrEvmAddress === null)
            throw new Error("The parameter 'idOrAliasOrEvmAddress' must be defined.");
        url_ = url_.replace("{idOrAliasOrEvmAddress}", encodeURIComponent("" + idOrAliasOrEvmAddress));
        if (spender_id === null)
            throw new Error("The parameter 'spender_id' cannot be null.");
        else if (spender_id !== undefined)
            url_ += "spender.id=" + encodeURIComponent("" + spender_id) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListCryptoAllowancesByAccountId(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListCryptoAllowancesByAccountId = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = CryptoAllowancesResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listTokenAllowancesByAccountId = function (idOrAliasOrEvmAddress, spender_id, token_id, limit, order) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/accounts/{idOrAliasOrEvmAddress}/allowances/tokens?";
        if (idOrAliasOrEvmAddress === undefined || idOrAliasOrEvmAddress === null)
            throw new Error("The parameter 'idOrAliasOrEvmAddress' must be defined.");
        url_ = url_.replace("{idOrAliasOrEvmAddress}", encodeURIComponent("" + idOrAliasOrEvmAddress));
        if (spender_id === null)
            throw new Error("The parameter 'spender_id' cannot be null.");
        else if (spender_id !== undefined)
            url_ += "spender.id=" + encodeURIComponent("" + spender_id) + "&";
        if (token_id === null)
            throw new Error("The parameter 'token_id' cannot be null.");
        else if (token_id !== undefined)
            url_ += "token.id=" + encodeURIComponent("" + token_id) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListTokenAllowancesByAccountId(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListTokenAllowancesByAccountId = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = TokenAllowancesResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listAccountBalances = function (account_id, account_balance, order, account_publickey, timestamp, limit) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/balances?";
        if (account_id === null)
            throw new Error("The parameter 'account_id' cannot be null.");
        else if (account_id !== undefined)
            url_ += "account.id=" + encodeURIComponent("" + account_id) + "&";
        if (account_balance === null)
            throw new Error("The parameter 'account_balance' cannot be null.");
        else if (account_balance !== undefined)
            url_ += "account.balance=" + encodeURIComponent("" + account_balance) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        if (account_publickey === null)
            throw new Error("The parameter 'account_publickey' cannot be null.");
        else if (account_publickey !== undefined)
            url_ += "account.publickey=" + encodeURIComponent("" + account_publickey) + "&";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListAccountBalances(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListAccountBalances = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = BalancesResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listBlocks = function (block_number, timestamp, order, limit) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/blocks?";
        if (block_number === null)
            throw new Error("The parameter 'block_number' cannot be null.");
        else if (block_number !== undefined)
            url_ += "block.number=" + encodeURIComponent("" + block_number) + "&";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListBlocks(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListBlocks = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = BlocksResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getByHashOrNumber = function (hashOrNumber) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/blocks/{hashOrNumber}";
        if (hashOrNumber === undefined || hashOrNumber === null)
            throw new Error("The parameter 'hashOrNumber' must be defined.");
        url_ = url_.replace("{hashOrNumber}", encodeURIComponent("" + hashOrNumber));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetByHashOrNumber(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetByHashOrNumber = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = Block.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listContracts = function (contract_id, limit, order) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/contracts?";
        if (contract_id === null)
            throw new Error("The parameter 'contract_id' cannot be null.");
        else if (contract_id !== undefined)
            url_ += "contract.id=" + encodeURIComponent("" + contract_id) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListContracts(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListContracts = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = ContractsResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getContractById = function (contractIdOrAddress, timestamp) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/contracts/{contractIdOrAddress}?";
        if (contractIdOrAddress === undefined || contractIdOrAddress === null)
            throw new Error("The parameter 'contractIdOrAddress' must be defined.");
        url_ = url_.replace("{contractIdOrAddress}", encodeURIComponent("" + contractIdOrAddress));
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetContractById(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetContractById = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = ContractResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listContractResults = function (contractIdOrAddress, from, limit, order, timestamp, block_number, block_hash, transaction_index, internal) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/contracts/{contractIdOrAddress}/results?";
        if (contractIdOrAddress === undefined || contractIdOrAddress === null)
            throw new Error("The parameter 'contractIdOrAddress' must be defined.");
        url_ = url_.replace("{contractIdOrAddress}", encodeURIComponent("" + contractIdOrAddress));
        if (from !== undefined && from !== null)
            url_ += "from=" + encodeURIComponent("" + from) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        if (block_number === null)
            throw new Error("The parameter 'block_number' cannot be null.");
        else if (block_number !== undefined)
            url_ += "block.number=" + encodeURIComponent("" + block_number) + "&";
        if (block_hash === null)
            throw new Error("The parameter 'block_hash' cannot be null.");
        else if (block_hash !== undefined)
            url_ += "block.hash=" + encodeURIComponent("" + block_hash) + "&";
        if (transaction_index === null)
            throw new Error("The parameter 'transaction_index' cannot be null.");
        else if (transaction_index !== undefined)
            url_ += "transaction.index=" + encodeURIComponent("" + transaction_index) + "&";
        if (internal === null)
            throw new Error("The parameter 'internal' cannot be null.");
        else if (internal !== undefined)
            url_ += "internal=" + encodeURIComponent("" + internal) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListContractResults(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListContractResults = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = ContractResultsResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getContractResultByIdAndTimestamp = function (contractIdOrAddress, timestamp) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/contracts/{contractIdOrAddress}/results/{timestamp}";
        if (contractIdOrAddress === undefined || contractIdOrAddress === null)
            throw new Error("The parameter 'contractIdOrAddress' must be defined.");
        url_ = url_.replace("{contractIdOrAddress}", encodeURIComponent("" + contractIdOrAddress));
        if (timestamp === undefined || timestamp === null)
            throw new Error("The parameter 'timestamp' must be defined.");
        url_ = url_.replace("{timestamp}", encodeURIComponent("" + timestamp));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetContractResultByIdAndTimestamp(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetContractResultByIdAndTimestamp = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = ContractResultResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 206) {
            return response.text().then(function (_responseText) {
                var result206 = null;
                var resultData206 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result206 = ContractResultResponse.fromJS(resultData206);
                return new SwaggerResponse(status, _headers, result206);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listAllContractsResults = function (from, limit, order, timestamp, block_number, block_hash, transaction_index, internal) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/contracts/results?";
        if (from !== undefined && from !== null)
            url_ += "from=" + encodeURIComponent("" + from) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        if (block_number === null)
            throw new Error("The parameter 'block_number' cannot be null.");
        else if (block_number !== undefined)
            url_ += "block.number=" + encodeURIComponent("" + block_number) + "&";
        if (block_hash === null)
            throw new Error("The parameter 'block_hash' cannot be null.");
        else if (block_hash !== undefined)
            url_ += "block.hash=" + encodeURIComponent("" + block_hash) + "&";
        if (transaction_index === null)
            throw new Error("The parameter 'transaction_index' cannot be null.");
        else if (transaction_index !== undefined)
            url_ += "transaction.index=" + encodeURIComponent("" + transaction_index) + "&";
        if (internal === null)
            throw new Error("The parameter 'internal' cannot be null.");
        else if (internal !== undefined)
            url_ += "internal=" + encodeURIComponent("" + internal) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListAllContractsResults(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListAllContractsResults = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = ContractResultsResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getContractResultByTransactionIdOrHash = function (transactionIdOrHash, nonce) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/contracts/results/{transactionIdOrHash}?";
        if (transactionIdOrHash === undefined || transactionIdOrHash === null)
            throw new Error("The parameter 'transactionIdOrHash' must be defined.");
        url_ = url_.replace("{transactionIdOrHash}", encodeURIComponent("" + transactionIdOrHash));
        if (nonce === null)
            throw new Error("The parameter 'nonce' cannot be null.");
        else if (nonce !== undefined)
            url_ += "nonce=" + encodeURIComponent("" + nonce) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetContractResultByTransactionIdOrHash(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetContractResultByTransactionIdOrHash = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = ContractResultResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 206) {
            return response.text().then(function (_responseText) {
                var result206 = null;
                var resultData206 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result206 = ContractResultResponse.fromJS(resultData206);
                return new SwaggerResponse(status, _headers, result206);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listContractLogs = function (contractIdOrAddress, index, limit, order, timestamp, topic0, topic1, topic2, topic3) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/contracts/{contractIdOrAddress}/results/logs?";
        if (contractIdOrAddress === undefined || contractIdOrAddress === null)
            throw new Error("The parameter 'contractIdOrAddress' must be defined.");
        url_ = url_.replace("{contractIdOrAddress}", encodeURIComponent("" + contractIdOrAddress));
        if (index === null)
            throw new Error("The parameter 'index' cannot be null.");
        else if (index !== undefined)
            url_ += "index=" + encodeURIComponent("" + index) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        if (topic0 === null)
            throw new Error("The parameter 'topic0' cannot be null.");
        else if (topic0 !== undefined)
            topic0 && topic0.forEach(function (item) { url_ += "topic0=" + encodeURIComponent("" + item) + "&"; });
        if (topic1 === null)
            throw new Error("The parameter 'topic1' cannot be null.");
        else if (topic1 !== undefined)
            topic1 && topic1.forEach(function (item) { url_ += "topic1=" + encodeURIComponent("" + item) + "&"; });
        if (topic2 === null)
            throw new Error("The parameter 'topic2' cannot be null.");
        else if (topic2 !== undefined)
            topic2 && topic2.forEach(function (item) { url_ += "topic2=" + encodeURIComponent("" + item) + "&"; });
        if (topic3 === null)
            throw new Error("The parameter 'topic3' cannot be null.");
        else if (topic3 !== undefined)
            topic3 && topic3.forEach(function (item) { url_ += "topic3=" + encodeURIComponent("" + item) + "&"; });
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListContractLogs(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListContractLogs = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = ContractLogsResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listContractsLogs = function (index, limit, order, timestamp, topic0, topic1, topic2, topic3) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/contracts/results/logs?";
        if (index === null)
            throw new Error("The parameter 'index' cannot be null.");
        else if (index !== undefined)
            url_ += "index=" + encodeURIComponent("" + index) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        if (topic0 === null)
            throw new Error("The parameter 'topic0' cannot be null.");
        else if (topic0 !== undefined)
            topic0 && topic0.forEach(function (item) { url_ += "topic0=" + encodeURIComponent("" + item) + "&"; });
        if (topic1 === null)
            throw new Error("The parameter 'topic1' cannot be null.");
        else if (topic1 !== undefined)
            topic1 && topic1.forEach(function (item) { url_ += "topic1=" + encodeURIComponent("" + item) + "&"; });
        if (topic2 === null)
            throw new Error("The parameter 'topic2' cannot be null.");
        else if (topic2 !== undefined)
            topic2 && topic2.forEach(function (item) { url_ += "topic2=" + encodeURIComponent("" + item) + "&"; });
        if (topic3 === null)
            throw new Error("The parameter 'topic3' cannot be null.");
        else if (topic3 !== undefined)
            topic3 && topic3.forEach(function (item) { url_ += "topic3=" + encodeURIComponent("" + item) + "&"; });
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListContractsLogs(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListContractsLogs = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = ContractLogsResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getNetworkExchangeRate = function (timestamp) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/network/exchangerate?";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetNetworkExchangeRate(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetNetworkExchangeRate = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = NetworkExchangeRateSetResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status === 500) {
            return response.text().then(function (_responseText) {
                var result500 = null;
                var resultData500 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result500 = ErrorDto.fromJS(resultData500);
                return throwException("Service Unavailable", status, _responseText, _headers, result500);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getNetworkFees = function (timestamp, order) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/network/fees?";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetNetworkFees(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetNetworkFees = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = NetworkFeesResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status === 500) {
            return response.text().then(function (_responseText) {
                var result500 = null;
                var resultData500 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result500 = ErrorDto.fromJS(resultData500);
                return throwException("Service Unavailable", status, _responseText, _headers, result500);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getNetworkNodes = function (file_id, node_id, limit, order) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/network/nodes?";
        if (file_id === null)
            throw new Error("The parameter 'file_id' cannot be null.");
        else if (file_id !== undefined)
            url_ += "file.id=" + encodeURIComponent("" + file_id) + "&";
        if (node_id === null)
            throw new Error("The parameter 'node_id' cannot be null.");
        else if (node_id !== undefined)
            url_ += "node.id=" + encodeURIComponent("" + node_id) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetNetworkNodes(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetNetworkNodes = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = NetworkNodesResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getNetworkSupply = function (timestamp) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/network/supply?";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetNetworkSupply(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetNetworkSupply = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = NetworkSupplyResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listSchedules = function (account_id, limit, order, schedule_id) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/schedules?";
        if (account_id === null)
            throw new Error("The parameter 'account_id' cannot be null.");
        else if (account_id !== undefined)
            url_ += "account.id=" + encodeURIComponent("" + account_id) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        if (schedule_id === null)
            throw new Error("The parameter 'schedule_id' cannot be null.");
        else if (schedule_id !== undefined)
            url_ += "schedule.id=" + encodeURIComponent("" + schedule_id) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListSchedules(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListSchedules = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = SchedulesResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getScheduleById = function (scheduleId) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/schedules/{scheduleId}";
        if (scheduleId === undefined || scheduleId === null)
            throw new Error("The parameter 'scheduleId' must be defined.");
        url_ = url_.replace("{scheduleId}", encodeURIComponent("" + scheduleId));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetScheduleById(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetScheduleById = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = Schedule.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listTransactions = function (account_id, limit, order, timestamp, transactiontype, result, type) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/transactions?";
        if (account_id === null)
            throw new Error("The parameter 'account_id' cannot be null.");
        else if (account_id !== undefined)
            url_ += "account.id=" + encodeURIComponent("" + account_id) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        if (transactiontype === null)
            throw new Error("The parameter 'transactiontype' cannot be null.");
        else if (transactiontype !== undefined)
            url_ += "transactiontype=" + encodeURIComponent("" + transactiontype) + "&";
        if (result === null)
            throw new Error("The parameter 'result' cannot be null.");
        else if (result !== undefined)
            url_ += "result=" + encodeURIComponent("" + result) + "&";
        if (type === null)
            throw new Error("The parameter 'type' cannot be null.");
        else if (type !== undefined)
            url_ += "type=" + encodeURIComponent("" + type) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListTransactions(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListTransactions = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = TransactionsResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getTransactionById = function (transactionId, nonce, scheduled) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/transactions/{transactionId}?";
        if (transactionId === undefined || transactionId === null)
            throw new Error("The parameter 'transactionId' must be defined.");
        url_ = url_.replace("{transactionId}", encodeURIComponent("" + transactionId));
        if (nonce === null)
            throw new Error("The parameter 'nonce' cannot be null.");
        else if (nonce !== undefined)
            url_ += "nonce=" + encodeURIComponent("" + nonce) + "&";
        if (scheduled === null)
            throw new Error("The parameter 'scheduled' cannot be null.");
        else if (scheduled !== undefined)
            url_ += "scheduled=" + encodeURIComponent("" + scheduled) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetTransactionById(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetTransactionById = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = TransactionByIdResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getStateproofInfo = function (transactionId, nonce, scheduled) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/transactions/{transactionId}/stateproof?";
        if (transactionId === undefined || transactionId === null)
            throw new Error("The parameter 'transactionId' must be defined.");
        url_ = url_.replace("{transactionId}", encodeURIComponent("" + transactionId));
        if (nonce === null)
            throw new Error("The parameter 'nonce' cannot be null.");
        else if (nonce !== undefined)
            url_ += "nonce=" + encodeURIComponent("" + nonce) + "&";
        if (scheduled === null)
            throw new Error("The parameter 'scheduled' cannot be null.");
        else if (scheduled !== undefined)
            url_ += "scheduled=" + encodeURIComponent("" + scheduled) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetStateproofInfo(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetStateproofInfo = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = StateProofResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Transaction Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status === 502) {
            return response.text().then(function (_responseText) {
                var result502 = null;
                var resultData502 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result502 = ErrorDto.fromJS(resultData502);
                return throwException("Service Unavailable", status, _responseText, _headers, result502);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listTopicMessagesById = function (topicId, sequencenumber, timestamp, encoding, limit, order) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/topics/{topicId}/messages?";
        if (topicId === undefined || topicId === null)
            throw new Error("The parameter 'topicId' must be defined.");
        url_ = url_.replace("{topicId}", encodeURIComponent("" + topicId));
        if (sequencenumber === null)
            throw new Error("The parameter 'sequencenumber' cannot be null.");
        else if (sequencenumber !== undefined)
            url_ += "sequencenumber=" + encodeURIComponent("" + sequencenumber) + "&";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        if (encoding === null)
            throw new Error("The parameter 'encoding' cannot be null.");
        else if (encoding !== undefined)
            url_ += "encoding=" + encodeURIComponent("" + encoding) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListTopicMessagesById(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListTopicMessagesById = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = TopicMessagesResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Topic Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getTopicMessageByIdAndSequenceNumber = function (topicId, sequencenumber) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/topics/{topicId}/messages/{sequenceNumber}";
        if (topicId === undefined || topicId === null)
            throw new Error("The parameter 'topicId' must be defined.");
        url_ = url_.replace("{topicId}", encodeURIComponent("" + topicId));
        if (sequencenumber === undefined || sequencenumber === null)
            throw new Error("The parameter 'sequencenumber' must be defined.");
        url_ = url_.replace("{sequencenumber}", encodeURIComponent("" + sequencenumber));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetTopicMessageByIdAndSequenceNumber(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetTopicMessageByIdAndSequenceNumber = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = TopicMessagesResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getTopicMessagesByConsensusTimestamp = function (timestamp) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/topics/messages/{consensusTimestamp}";
        if (timestamp === undefined || timestamp === null)
            throw new Error("The parameter 'timestamp' must be defined.");
        url_ = url_.replace("{timestamp}", encodeURIComponent("" + timestamp));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetTopicMessagesByConsensusTimestamp(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetTopicMessagesByConsensusTimestamp = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = TopicMessage.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listTokens = function (publickey, token_id, type, limit, account_id, order) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/tokens?";
        if (publickey === null)
            throw new Error("The parameter 'publickey' cannot be null.");
        else if (publickey !== undefined)
            url_ += "publickey=" + encodeURIComponent("" + publickey) + "&";
        if (token_id === null)
            throw new Error("The parameter 'token_id' cannot be null.");
        else if (token_id !== undefined)
            url_ += "token.id=" + encodeURIComponent("" + token_id) + "&";
        if (type === null)
            throw new Error("The parameter 'type' cannot be null.");
        else if (type !== undefined)
            type && type.forEach(function (item) { url_ += "type=" + encodeURIComponent("" + item) + "&"; });
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (account_id === null)
            throw new Error("The parameter 'account_id' cannot be null.");
        else if (account_id !== undefined)
            url_ += "account.id=" + encodeURIComponent("" + account_id) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListTokens(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListTokens = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = TokensResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.getTokenById = function (tokenId, timestamp) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/tokens/{tokenId}?";
        if (tokenId === undefined || tokenId === null)
            throw new Error("The parameter 'tokenId' must be defined.");
        url_ = url_.replace("{tokenId}", encodeURIComponent("" + tokenId));
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            url_ += "timestamp=" + encodeURIComponent("" + timestamp) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processGetTokenById(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processGetTokenById = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = TokenInfo.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listTokenBalancesById = function (tokenId, account_id, account_balance, order, account_publickey, timestamp, limit) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/tokens/{tokenId}/balances?";
        if (tokenId === undefined || tokenId === null)
            throw new Error("The parameter 'tokenId' must be defined.");
        url_ = url_.replace("{tokenId}", encodeURIComponent("" + tokenId));
        if (account_id === null)
            throw new Error("The parameter 'account_id' cannot be null.");
        else if (account_id !== undefined)
            url_ += "account.id=" + encodeURIComponent("" + account_id) + "&";
        if (account_balance === null)
            throw new Error("The parameter 'account_balance' cannot be null.");
        else if (account_balance !== undefined)
            url_ += "account.balance=" + encodeURIComponent("" + account_balance) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        if (account_publickey === null)
            throw new Error("The parameter 'account_publickey' cannot be null.");
        else if (account_publickey !== undefined)
            url_ += "account.publickey=" + encodeURIComponent("" + account_publickey) + "&";
        if (timestamp === null)
            throw new Error("The parameter 'timestamp' cannot be null.");
        else if (timestamp !== undefined)
            timestamp && timestamp.forEach(function (item) { url_ += "timestamp=" + encodeURIComponent("" + item) + "&"; });
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListTokenBalancesById(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListTokenBalancesById = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = TokenBalancesResponse.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listNfts = function (tokenId, account_id, limit, order) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/tokens/{tokenId}/nfts?";
        if (tokenId === undefined || tokenId === null)
            throw new Error("The parameter 'tokenId' must be defined.");
        url_ = url_.replace("{tokenId}", encodeURIComponent("" + tokenId));
        if (account_id === null)
            throw new Error("The parameter 'account_id' cannot be null.");
        else if (account_id !== undefined)
            url_ += "account.id=" + encodeURIComponent("" + account_id) + "&";
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListNfts(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListNfts = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = Nfts.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listNftBySerialnumber = function (tokenId, serialNumber) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/tokens/{tokenId}/nfts/{serialNumber}";
        if (tokenId === undefined || tokenId === null)
            throw new Error("The parameter 'tokenId' must be defined.");
        url_ = url_.replace("{tokenId}", encodeURIComponent("" + tokenId));
        if (serialNumber === undefined || serialNumber === null)
            throw new Error("The parameter 'serialNumber' must be defined.");
        url_ = url_.replace("{serialNumber}", encodeURIComponent("" + serialNumber));
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListNftBySerialnumber(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListNftBySerialnumber = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = Nft.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status === 404) {
            return response.text().then(function (_responseText) {
                var result404 = null;
                var resultData404 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result404 = ErrorDto.fromJS(resultData404);
                return throwException("Not Found", status, _responseText, _headers, result404);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    HederaMirrorNodeAPIClient.prototype.listNftTransactions = function (tokenId, serialNumber, limit, order) {
        var _this = this;
        var url_ = this.baseUrl + "/api/v1/tokens/{tokenId}/nfts/{serialNumber}/transactions?";
        if (tokenId === undefined || tokenId === null)
            throw new Error("The parameter 'tokenId' must be defined.");
        url_ = url_.replace("{tokenId}", encodeURIComponent("" + tokenId));
        if (serialNumber === undefined || serialNumber === null)
            throw new Error("The parameter 'serialNumber' must be defined.");
        url_ = url_.replace("{serialNumber}", encodeURIComponent("" + serialNumber));
        if (limit === null)
            throw new Error("The parameter 'limit' cannot be null.");
        else if (limit !== undefined)
            url_ += "limit=" + encodeURIComponent("" + limit) + "&";
        if (order === null)
            throw new Error("The parameter 'order' cannot be null.");
        else if (order !== undefined)
            url_ += "order=" + encodeURIComponent("" + order) + "&";
        url_ = url_.replace(/[?&]$/, "");
        var options_ = {
            method: "GET",
            headers: {
                "Accept": "application/json"
            }
        };
        return this.http.fetch(url_, options_).then(function (_response) {
            return _this.processListNftTransactions(_response);
        });
    };
    HederaMirrorNodeAPIClient.prototype.processListNftTransactions = function (response) {
        var _this = this;
        var status = response.status;
        var _headers = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach(function (v, k) { return _headers[k] = v; });
        }
        ;
        if (status === 200) {
            return response.text().then(function (_responseText) {
                var result200 = null;
                var resultData200 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result200 = NftTransactionHistory.fromJS(resultData200);
                return new SwaggerResponse(status, _headers, result200);
            });
        }
        else if (status === 206) {
            return response.text().then(function (_responseText) {
                var result206 = null;
                var resultData206 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result206 = NftTransactionHistory.fromJS(resultData206);
                return new SwaggerResponse(status, _headers, result206);
            });
        }
        else if (status === 400) {
            return response.text().then(function (_responseText) {
                var result400 = null;
                var resultData400 = _responseText === "" ? null : JSON.parse(_responseText, _this.jsonParseReviver);
                result400 = ErrorDto.fromJS(resultData400);
                return throwException("Invalid parameter", status, _responseText, _headers, result400);
            });
        }
        else if (status !== 200 && status !== 204) {
            return response.text().then(function (_responseText) {
                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve(new SwaggerResponse(status, _headers, null));
    };
    return HederaMirrorNodeAPIClient;
}());
export { HederaMirrorNodeAPIClient };
var AccountsResponse = (function () {
    function AccountsResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    AccountsResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["accounts"])) {
                this.accounts = [];
                for (var _i = 0, _a = _data["accounts"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.accounts.push(AccountInfo.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    AccountsResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new AccountsResponse();
        result.init(data);
        return result;
    };
    AccountsResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.accounts)) {
            data["accounts"] = [];
            for (var _i = 0, _a = this.accounts; _i < _a.length; _i++) {
                var item = _a[_i];
                data["accounts"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return AccountsResponse;
}());
export { AccountsResponse };
var Allowance = (function () {
    function Allowance(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Allowance.prototype.init = function (_data) {
        if (_data) {
            this.amount_granted = _data["amount_granted"];
            this.owner = _data["owner"];
            this.spender = _data["spender"];
            this.timestamp = _data["timestamp"] ? TimestampRange.fromJS(_data["timestamp"]) : undefined;
        }
    };
    Allowance.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Allowance();
        result.init(data);
        return result;
    };
    Allowance.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["amount_granted"] = this.amount_granted;
        data["owner"] = this.owner;
        data["spender"] = this.spender;
        data["timestamp"] = this.timestamp ? this.timestamp.toJSON() : undefined;
        return data;
    };
    return Allowance;
}());
export { Allowance };
var BalancesResponse = (function () {
    function BalancesResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    BalancesResponse.prototype.init = function (_data) {
        if (_data) {
            this.timestamp = _data["timestamp"];
            if (Array.isArray(_data["balances"])) {
                this.balances = [];
                for (var _i = 0, _a = _data["balances"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.balances.push(AccountBalance.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    BalancesResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new BalancesResponse();
        result.init(data);
        return result;
    };
    BalancesResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["timestamp"] = this.timestamp;
        if (Array.isArray(this.balances)) {
            data["balances"] = [];
            for (var _i = 0, _a = this.balances; _i < _a.length; _i++) {
                var item = _a[_i];
                data["balances"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return BalancesResponse;
}());
export { BalancesResponse };
var Contract = (function () {
    function Contract(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Contract.prototype.init = function (_data) {
        if (_data) {
            this.admin_key = _data["admin_key"] ? Key.fromJS(_data["admin_key"]) : undefined;
            this.auto_renew_account = _data["auto_renew_account"];
            this.auto_renew_period = _data["auto_renew_period"];
            this.contract_id = _data["contract_id"];
            this.created_timestamp = _data["created_timestamp"];
            this.deleted = _data["deleted"];
            this.evm_address = _data["evm_address"];
            this.expiration_timestamp = _data["expiration_timestamp"];
            this.file_id = _data["file_id"];
            this.max_automatic_token_associations = _data["max_automatic_token_associations"];
            this.memo = _data["memo"];
            this.obtainer_id = _data["obtainer_id"];
            this.permanent_removal = _data["permanent_removal"];
            this.proxy_account_id = _data["proxy_account_id"];
            this.timestamp = _data["timestamp"] ? TimestampRange.fromJS(_data["timestamp"]) : undefined;
        }
    };
    Contract.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Contract();
        result.init(data);
        return result;
    };
    Contract.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["admin_key"] = this.admin_key ? this.admin_key.toJSON() : undefined;
        data["auto_renew_account"] = this.auto_renew_account;
        data["auto_renew_period"] = this.auto_renew_period;
        data["contract_id"] = this.contract_id;
        data["created_timestamp"] = this.created_timestamp;
        data["deleted"] = this.deleted;
        data["evm_address"] = this.evm_address;
        data["expiration_timestamp"] = this.expiration_timestamp;
        data["file_id"] = this.file_id;
        data["max_automatic_token_associations"] = this.max_automatic_token_associations;
        data["memo"] = this.memo;
        data["obtainer_id"] = this.obtainer_id;
        data["permanent_removal"] = this.permanent_removal;
        data["proxy_account_id"] = this.proxy_account_id;
        data["timestamp"] = this.timestamp ? this.timestamp.toJSON() : undefined;
        return data;
    };
    return Contract;
}());
export { Contract };
var ContractResponse = (function (_super) {
    __extends(ContractResponse, _super);
    function ContractResponse(data) {
        return _super.call(this, data) || this;
    }
    ContractResponse.prototype.init = function (_data) {
        _super.prototype.init.call(this, _data);
        if (_data) {
            this.bytecode = _data["bytecode"];
        }
    };
    ContractResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ContractResponse();
        result.init(data);
        return result;
    };
    ContractResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["bytecode"] = this.bytecode;
        _super.prototype.toJSON.call(this, data);
        return data;
    };
    return ContractResponse;
}(Contract));
export { ContractResponse };
var ContractsResponse = (function () {
    function ContractsResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ContractsResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["contracts"])) {
                this.contracts = [];
                for (var _i = 0, _a = _data["contracts"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.contracts.push(Contract.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    ContractsResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ContractsResponse();
        result.init(data);
        return result;
    };
    ContractsResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.contracts)) {
            data["contracts"] = [];
            for (var _i = 0, _a = this.contracts; _i < _a.length; _i++) {
                var item = _a[_i];
                data["contracts"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return ContractsResponse;
}());
export { ContractsResponse };
var ContractResultResponse = (function () {
    function ContractResultResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ContractResultResponse.prototype.init = function (_data) {
        if (_data) {
            this.contracts = _data["contracts"] ? ContractResultDetails.fromJS(_data["contracts"]) : undefined;
        }
    };
    ContractResultResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ContractResultResponse();
        result.init(data);
        return result;
    };
    ContractResultResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["contracts"] = this.contracts ? this.contracts.toJSON() : undefined;
        return data;
    };
    return ContractResultResponse;
}());
export { ContractResultResponse };
var ContractResultsResponse = (function () {
    function ContractResultsResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ContractResultsResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["contracts"])) {
                this.contracts = [];
                for (var _i = 0, _a = _data["contracts"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.contracts.push(ContractResult.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    ContractResultsResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ContractResultsResponse();
        result.init(data);
        return result;
    };
    ContractResultsResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.contracts)) {
            data["contracts"] = [];
            for (var _i = 0, _a = this.contracts; _i < _a.length; _i++) {
                var item = _a[_i];
                data["contracts"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return ContractResultsResponse;
}());
export { ContractResultsResponse };
var ContractLogsResponse = (function () {
    function ContractLogsResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ContractLogsResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["logs"])) {
                this.logs = [];
                for (var _i = 0, _a = _data["logs"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.logs.push(ContractLog.fromJS(item));
                }
            }
        }
    };
    ContractLogsResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ContractLogsResponse();
        result.init(data);
        return result;
    };
    ContractLogsResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.logs)) {
            data["logs"] = [];
            for (var _i = 0, _a = this.logs; _i < _a.length; _i++) {
                var item = _a[_i];
                data["logs"].push(item.toJSON());
            }
        }
        return data;
    };
    return ContractLogsResponse;
}());
export { ContractLogsResponse };
var CryptoAllowancesResponse = (function () {
    function CryptoAllowancesResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    CryptoAllowancesResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["allowances"])) {
                this.allowances = [];
                for (var _i = 0, _a = _data["allowances"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.allowances.push(CryptoAllowance.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    CryptoAllowancesResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new CryptoAllowancesResponse();
        result.init(data);
        return result;
    };
    CryptoAllowancesResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.allowances)) {
            data["allowances"] = [];
            for (var _i = 0, _a = this.allowances; _i < _a.length; _i++) {
                var item = _a[_i];
                data["allowances"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return CryptoAllowancesResponse;
}());
export { CryptoAllowancesResponse };
var NetworkExchangeRateSetResponse = (function () {
    function NetworkExchangeRateSetResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    NetworkExchangeRateSetResponse.prototype.init = function (_data) {
        if (_data) {
            this.current_rate = _data["current_rate"] ? ExchangeRate.fromJS(_data["current_rate"]) : undefined;
            this.next_rate = _data["next_rate"] ? ExchangeRate.fromJS(_data["next_rate"]) : undefined;
            this.timestamp = _data["timestamp"];
        }
    };
    NetworkExchangeRateSetResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new NetworkExchangeRateSetResponse();
        result.init(data);
        return result;
    };
    NetworkExchangeRateSetResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["current_rate"] = this.current_rate ? this.current_rate.toJSON() : undefined;
        data["next_rate"] = this.next_rate ? this.next_rate.toJSON() : undefined;
        data["timestamp"] = this.timestamp;
        return data;
    };
    return NetworkExchangeRateSetResponse;
}());
export { NetworkExchangeRateSetResponse };
var NetworkFeesResponse = (function () {
    function NetworkFeesResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    NetworkFeesResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["fees"])) {
                this.fees = [];
                for (var _i = 0, _a = _data["fees"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.fees.push(NetworkFee.fromJS(item));
                }
            }
            this.timestamp = _data["timestamp"];
        }
    };
    NetworkFeesResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new NetworkFeesResponse();
        result.init(data);
        return result;
    };
    NetworkFeesResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.fees)) {
            data["fees"] = [];
            for (var _i = 0, _a = this.fees; _i < _a.length; _i++) {
                var item = _a[_i];
                data["fees"].push(item.toJSON());
            }
        }
        data["timestamp"] = this.timestamp;
        return data;
    };
    return NetworkFeesResponse;
}());
export { NetworkFeesResponse };
var NetworkNodesResponse = (function () {
    function NetworkNodesResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    NetworkNodesResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["nodes"])) {
                this.nodes = [];
                for (var _i = 0, _a = _data["nodes"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.nodes.push(NetworkNode.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    NetworkNodesResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new NetworkNodesResponse();
        result.init(data);
        return result;
    };
    NetworkNodesResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.nodes)) {
            data["nodes"] = [];
            for (var _i = 0, _a = this.nodes; _i < _a.length; _i++) {
                var item = _a[_i];
                data["nodes"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return NetworkNodesResponse;
}());
export { NetworkNodesResponse };
var NetworkSupplyResponse = (function () {
    function NetworkSupplyResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    NetworkSupplyResponse.prototype.init = function (_data) {
        if (_data) {
            this.released_supply = _data["released_supply"];
            this.timestamp = _data["timestamp"];
            this.total_supply = _data["total_supply"];
        }
    };
    NetworkSupplyResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new NetworkSupplyResponse();
        result.init(data);
        return result;
    };
    NetworkSupplyResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["released_supply"] = this.released_supply;
        data["timestamp"] = this.timestamp;
        data["total_supply"] = this.total_supply;
        return data;
    };
    return NetworkSupplyResponse;
}());
export { NetworkSupplyResponse };
var SchedulesResponse = (function () {
    function SchedulesResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    SchedulesResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["schedules"])) {
                this.schedules = [];
                for (var _i = 0, _a = _data["schedules"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.schedules.push(Schedule.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    SchedulesResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new SchedulesResponse();
        result.init(data);
        return result;
    };
    SchedulesResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.schedules)) {
            data["schedules"] = [];
            for (var _i = 0, _a = this.schedules; _i < _a.length; _i++) {
                var item = _a[_i];
                data["schedules"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return SchedulesResponse;
}());
export { SchedulesResponse };
var BlocksResponse = (function () {
    function BlocksResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    BlocksResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["schedules"])) {
                this.schedules = [];
                for (var _i = 0, _a = _data["schedules"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.schedules.push(Block.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    BlocksResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new BlocksResponse();
        result.init(data);
        return result;
    };
    BlocksResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.schedules)) {
            data["schedules"] = [];
            for (var _i = 0, _a = this.schedules; _i < _a.length; _i++) {
                var item = _a[_i];
                data["schedules"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return BlocksResponse;
}());
export { BlocksResponse };
var StateProofResponse = (function () {
    function StateProofResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    StateProofResponse.prototype.init = function (_data) {
        if (_data) {
            this.transactions = _data["transactions"] ? StateProofFiles.fromJS(_data["transactions"]) : undefined;
        }
    };
    StateProofResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new StateProofResponse();
        result.init(data);
        return result;
    };
    StateProofResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["transactions"] = this.transactions ? this.transactions.toJSON() : undefined;
        return data;
    };
    return StateProofResponse;
}());
export { StateProofResponse };
var TokenAllowancesResponse = (function () {
    function TokenAllowancesResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    TokenAllowancesResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["allowances"])) {
                this.allowances = [];
                for (var _i = 0, _a = _data["allowances"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.allowances.push(TokenAllowance.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    TokenAllowancesResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TokenAllowancesResponse();
        result.init(data);
        return result;
    };
    TokenAllowancesResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.allowances)) {
            data["allowances"] = [];
            for (var _i = 0, _a = this.allowances; _i < _a.length; _i++) {
                var item = _a[_i];
                data["allowances"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return TokenAllowancesResponse;
}());
export { TokenAllowancesResponse };
var TokenBalancesResponse = (function () {
    function TokenBalancesResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    TokenBalancesResponse.prototype.init = function (_data) {
        if (_data) {
            this.timestamp = _data["timestamp"];
            if (Array.isArray(_data["balances"])) {
                this.balances = [];
                for (var _i = 0, _a = _data["balances"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.balances.push(Balance.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    TokenBalancesResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TokenBalancesResponse();
        result.init(data);
        return result;
    };
    TokenBalancesResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["timestamp"] = this.timestamp;
        if (Array.isArray(this.balances)) {
            data["balances"] = [];
            for (var _i = 0, _a = this.balances; _i < _a.length; _i++) {
                var item = _a[_i];
                data["balances"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return TokenBalancesResponse;
}());
export { TokenBalancesResponse };
var TokensResponse = (function () {
    function TokensResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    TokensResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["tokens"])) {
                this.tokens = [];
                for (var _i = 0, _a = _data["tokens"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.tokens.push(Token.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    TokensResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TokensResponse();
        result.init(data);
        return result;
    };
    TokensResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.tokens)) {
            data["tokens"] = [];
            for (var _i = 0, _a = this.tokens; _i < _a.length; _i++) {
                var item = _a[_i];
                data["tokens"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return TokensResponse;
}());
export { TokensResponse };
var TopicMessagesResponse = (function () {
    function TopicMessagesResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    TopicMessagesResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["messages"])) {
                this.messages = [];
                for (var _i = 0, _a = _data["messages"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.messages.push(TopicMessage.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    TopicMessagesResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TopicMessagesResponse();
        result.init(data);
        return result;
    };
    TopicMessagesResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.messages)) {
            data["messages"] = [];
            for (var _i = 0, _a = this.messages; _i < _a.length; _i++) {
                var item = _a[_i];
                data["messages"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return TopicMessagesResponse;
}());
export { TopicMessagesResponse };
var TransactionByIdResponse = (function () {
    function TransactionByIdResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    TransactionByIdResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["transactions"])) {
                this.transactions = [];
                for (var _i = 0, _a = _data["transactions"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.transactions.push(TransactionDetail.fromJS(item));
                }
            }
        }
    };
    TransactionByIdResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TransactionByIdResponse();
        result.init(data);
        return result;
    };
    TransactionByIdResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.transactions)) {
            data["transactions"] = [];
            for (var _i = 0, _a = this.transactions; _i < _a.length; _i++) {
                var item = _a[_i];
                data["transactions"].push(item.toJSON());
            }
        }
        return data;
    };
    return TransactionByIdResponse;
}());
export { TransactionByIdResponse };
var TransactionsResponse = (function () {
    function TransactionsResponse(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    TransactionsResponse.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["transactions"])) {
                this.transactions = [];
                for (var _i = 0, _a = _data["transactions"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.transactions.push(Transaction.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    TransactionsResponse.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TransactionsResponse();
        result.init(data);
        return result;
    };
    TransactionsResponse.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.transactions)) {
            data["transactions"] = [];
            for (var _i = 0, _a = this.transactions; _i < _a.length; _i++) {
                var item = _a[_i];
                data["transactions"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return TransactionsResponse;
}());
export { TransactionsResponse };
var AccountInfo = (function () {
    function AccountInfo(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    AccountInfo.prototype.init = function (_data) {
        if (_data) {
            this.account = _data["account"];
            this.alias = _data["alias"];
            this.auto_renew_period = _data["auto_renew_period"];
            this.balance = _data["balance"] ? Balance.fromJS(_data["balance"]) : undefined;
            this.deleted = _data["deleted"];
            this.ethereum_nonce = _data["ethereum_nonce"];
            this.evm_address = _data["evm_address"];
            this.expiry_timestamp = _data["expiry_timestamp"];
            this.key = _data["key"] ? Key.fromJS(_data["key"]) : undefined;
            this.max_automatic_token_associations = _data["max_automatic_token_associations"];
            this.memo = _data["memo"];
            this.receiver_sig_required = _data["receiver_sig_required"];
        }
    };
    AccountInfo.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new AccountInfo();
        result.init(data);
        return result;
    };
    AccountInfo.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["account"] = this.account;
        data["alias"] = this.alias;
        data["auto_renew_period"] = this.auto_renew_period;
        data["balance"] = this.balance ? this.balance.toJSON() : undefined;
        data["deleted"] = this.deleted;
        data["ethereum_nonce"] = this.ethereum_nonce;
        data["evm_address"] = this.evm_address;
        data["expiry_timestamp"] = this.expiry_timestamp;
        data["key"] = this.key ? this.key.toJSON() : undefined;
        data["max_automatic_token_associations"] = this.max_automatic_token_associations;
        data["memo"] = this.memo;
        data["receiver_sig_required"] = this.receiver_sig_required;
        return data;
    };
    return AccountInfo;
}());
export { AccountInfo };
var AccountBalance = (function () {
    function AccountBalance(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
        if (!data) {
            this.tokens = [];
        }
    }
    AccountBalance.prototype.init = function (_data) {
        if (_data) {
            this.account = _data["account"];
            this.balance = _data["balance"];
            if (Array.isArray(_data["tokens"])) {
                this.tokens = [];
                for (var _i = 0, _a = _data["tokens"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.tokens.push(TokenBalance.fromJS(item));
                }
            }
        }
    };
    AccountBalance.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new AccountBalance();
        result.init(data);
        return result;
    };
    AccountBalance.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["account"] = this.account;
        data["balance"] = this.balance;
        if (Array.isArray(this.tokens)) {
            data["tokens"] = [];
            for (var _i = 0, _a = this.tokens; _i < _a.length; _i++) {
                var item = _a[_i];
                data["tokens"].push(item.toJSON());
            }
        }
        return data;
    };
    return AccountBalance;
}());
export { AccountBalance };
var AccountBalanceTransactions = (function () {
    function AccountBalanceTransactions(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    AccountBalanceTransactions.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["transactions"])) {
                this.transactions = [];
                for (var _i = 0, _a = _data["transactions"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.transactions.push(Transaction.fromJS(item));
                }
            }
            this.balance = _data["balance"] ? Balance.fromJS(_data["balance"]) : undefined;
            this.account = _data["account"];
            this.alias = _data["alias"];
            this.ethereum_nonce = _data["ethereum_nonce"];
            this.evm_address = _data["evm_address"];
            this.expiry_timestamp = _data["expiry_timestamp"];
            this.auto_renew_period = _data["auto_renew_period"];
            this.key = _data["key"] ? Key.fromJS(_data["key"]) : undefined;
            this.deleted = _data["deleted"];
            this.max_automatic_token_associations = _data["max_automatic_token_associations"];
            this.memo = _data["memo"];
            this.receiver_sig_required = _data["receiver_sig_required"];
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    AccountBalanceTransactions.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new AccountBalanceTransactions();
        result.init(data);
        return result;
    };
    AccountBalanceTransactions.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.transactions)) {
            data["transactions"] = [];
            for (var _i = 0, _a = this.transactions; _i < _a.length; _i++) {
                var item = _a[_i];
                data["transactions"].push(item.toJSON());
            }
        }
        data["balance"] = this.balance ? this.balance.toJSON() : undefined;
        data["account"] = this.account;
        data["alias"] = this.alias;
        data["ethereum_nonce"] = this.ethereum_nonce;
        data["evm_address"] = this.evm_address;
        data["expiry_timestamp"] = this.expiry_timestamp;
        data["auto_renew_period"] = this.auto_renew_period;
        data["key"] = this.key ? this.key.toJSON() : undefined;
        data["deleted"] = this.deleted;
        data["max_automatic_token_associations"] = this.max_automatic_token_associations;
        data["memo"] = this.memo;
        data["receiver_sig_required"] = this.receiver_sig_required;
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return AccountBalanceTransactions;
}());
export { AccountBalanceTransactions };
var Balance = (function () {
    function Balance(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
        if (!data) {
            this.tokens = [];
        }
    }
    Balance.prototype.init = function (_data) {
        if (_data) {
            this.timestamp = _data["timestamp"];
            this.balance = _data["balance"];
            if (Array.isArray(_data["tokens"])) {
                this.tokens = [];
                for (var _i = 0, _a = _data["tokens"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.tokens.push(Tokens.fromJS(item));
                }
            }
        }
    };
    Balance.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Balance();
        result.init(data);
        return result;
    };
    Balance.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["timestamp"] = this.timestamp;
        data["balance"] = this.balance;
        if (Array.isArray(this.tokens)) {
            data["tokens"] = [];
            for (var _i = 0, _a = this.tokens; _i < _a.length; _i++) {
                var item = _a[_i];
                data["tokens"].push(item.toJSON());
            }
        }
        return data;
    };
    return Balance;
}());
export { Balance };
var ChunkInfo = (function () {
    function ChunkInfo(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ChunkInfo.prototype.init = function (_data) {
        if (_data) {
            this.initial_transaction_id = _data["initial_transaction_id"] ? TransactionId.fromJS(_data["initial_transaction_id"]) : undefined;
            this.number = _data["number"];
            this.total = _data["total"];
        }
    };
    ChunkInfo.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ChunkInfo();
        result.init(data);
        return result;
    };
    ChunkInfo.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["initial_transaction_id"] = this.initial_transaction_id ? this.initial_transaction_id.toJSON() : undefined;
        data["number"] = this.number;
        data["total"] = this.total;
        return data;
    };
    return ChunkInfo;
}());
export { ChunkInfo };
var ContractResultLog = (function () {
    function ContractResultLog(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ContractResultLog.prototype.init = function (_data) {
        if (_data) {
            this.address = _data["address"];
            this.bloom = _data["bloom"];
            this.contract_id = _data["contract_id"];
            this.data = _data["data"];
            this.index = _data["index"];
            if (Array.isArray(_data["topics"])) {
                this.topics = [];
                for (var _i = 0, _a = _data["topics"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.topics.push(item);
                }
            }
        }
    };
    ContractResultLog.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ContractResultLog();
        result.init(data);
        return result;
    };
    ContractResultLog.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["address"] = this.address;
        data["bloom"] = this.bloom;
        data["contract_id"] = this.contract_id;
        data["data"] = this.data;
        data["index"] = this.index;
        if (Array.isArray(this.topics)) {
            data["topics"] = [];
            for (var _i = 0, _a = this.topics; _i < _a.length; _i++) {
                var item = _a[_i];
                data["topics"].push(item);
            }
        }
        return data;
    };
    return ContractResultLog;
}());
export { ContractResultLog };
var ContractLog = (function (_super) {
    __extends(ContractLog, _super);
    function ContractLog(data) {
        return _super.call(this, data) || this;
    }
    ContractLog.prototype.init = function (_data) {
        _super.prototype.init.call(this, _data);
        if (_data) {
            this.root_contract_id = _data["root_contract_id"];
            this.timestamp = _data["timestamp"];
        }
    };
    ContractLog.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ContractLog();
        result.init(data);
        return result;
    };
    ContractLog.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["root_contract_id"] = this.root_contract_id;
        data["timestamp"] = this.timestamp;
        _super.prototype.toJSON.call(this, data);
        return data;
    };
    return ContractLog;
}(ContractResultLog));
export { ContractLog };
var ContractResult = (function () {
    function ContractResult(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ContractResult.prototype.init = function (_data) {
        if (_data) {
            this.amount = _data["amount"];
            this.bloom = _data["bloom"];
            this.call_result = _data["call_result"];
            this.contract_id = _data["contract_id"];
            if (Array.isArray(_data["created_contract_ids"])) {
                this.created_contract_ids = [];
                for (var _i = 0, _a = _data["created_contract_ids"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.created_contract_ids.push(item);
                }
            }
            this.error_message = _data["error_message"];
            this.from = _data["from"];
            this.function_parameters = _data["function_parameters"];
            this.gas_limit = _data["gas_limit"];
            this.gas_used = _data["gas_used"];
            this.hash = _data["hash"];
            this.result = _data["result"];
            this.status = _data["status"];
            this.timestamp = _data["timestamp"];
            this.to = _data["to"];
        }
    };
    ContractResult.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ContractResult();
        result.init(data);
        return result;
    };
    ContractResult.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["amount"] = this.amount;
        data["bloom"] = this.bloom;
        data["call_result"] = this.call_result;
        data["contract_id"] = this.contract_id;
        if (Array.isArray(this.created_contract_ids)) {
            data["created_contract_ids"] = [];
            for (var _i = 0, _a = this.created_contract_ids; _i < _a.length; _i++) {
                var item = _a[_i];
                data["created_contract_ids"].push(item);
            }
        }
        data["error_message"] = this.error_message;
        data["from"] = this.from;
        data["function_parameters"] = this.function_parameters;
        data["gas_limit"] = this.gas_limit;
        data["gas_used"] = this.gas_used;
        data["hash"] = this.hash;
        data["result"] = this.result;
        data["status"] = this.status;
        data["timestamp"] = this.timestamp;
        data["to"] = this.to;
        return data;
    };
    return ContractResult;
}());
export { ContractResult };
var ContractResultDetails = (function (_super) {
    __extends(ContractResultDetails, _super);
    function ContractResultDetails(data) {
        return _super.call(this, data) || this;
    }
    ContractResultDetails.prototype.init = function (_data) {
        _super.prototype.init.call(this, _data);
        if (_data) {
            this.access_list = _data["access_list"];
            this.block_gas_used = _data["block_gas_used"];
            this.block_hash = _data["block_hash"];
            this.block_number = _data["block_number"];
            this.chain_id = _data["chain_id"];
            this.hash = _data["hash"];
            this.gas_price = _data["gas_price"];
            if (Array.isArray(_data["logs"])) {
                this.logs = [];
                for (var _i = 0, _a = _data["logs"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.logs.push(ContractResultLog.fromJS(item));
                }
            }
            this.max_fee_per_gas = _data["max_fee_per_gas"];
            this.max_priority_fee_per_gas = _data["max_priority_fee_per_gas"];
            this.nonce = _data["nonce"];
            this.r = _data["r"];
            this.s = _data["s"];
            if (Array.isArray(_data["state_changes"])) {
                this.state_changes = [];
                for (var _b = 0, _c = _data["state_changes"]; _b < _c.length; _b++) {
                    var item = _c[_b];
                    this.state_changes.push(ContractResultStateChange.fromJS(item));
                }
            }
            this.transaction_index = _data["transaction_index"];
            this.type = _data["type"];
            this.v = _data["v"];
        }
    };
    ContractResultDetails.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ContractResultDetails();
        result.init(data);
        return result;
    };
    ContractResultDetails.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["access_list"] = this.access_list;
        data["block_gas_used"] = this.block_gas_used;
        data["block_hash"] = this.block_hash;
        data["block_number"] = this.block_number;
        data["chain_id"] = this.chain_id;
        data["hash"] = this.hash;
        data["gas_price"] = this.gas_price;
        if (Array.isArray(this.logs)) {
            data["logs"] = [];
            for (var _i = 0, _a = this.logs; _i < _a.length; _i++) {
                var item = _a[_i];
                data["logs"].push(item.toJSON());
            }
        }
        data["max_fee_per_gas"] = this.max_fee_per_gas;
        data["max_priority_fee_per_gas"] = this.max_priority_fee_per_gas;
        data["nonce"] = this.nonce;
        data["r"] = this.r;
        data["s"] = this.s;
        if (Array.isArray(this.state_changes)) {
            data["state_changes"] = [];
            for (var _b = 0, _c = this.state_changes; _b < _c.length; _b++) {
                var item = _c[_b];
                data["state_changes"].push(item.toJSON());
            }
        }
        data["transaction_index"] = this.transaction_index;
        data["type"] = this.type;
        data["v"] = this.v;
        _super.prototype.toJSON.call(this, data);
        return data;
    };
    return ContractResultDetails;
}(ContractResult));
export { ContractResultDetails };
var ContractResultStateChange = (function () {
    function ContractResultStateChange(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ContractResultStateChange.prototype.init = function (_data) {
        if (_data) {
            this.address = _data["address"];
            this.contract_id = _data["contract_id"];
            this.slot = _data["slot"];
            this.value_read = _data["value_read"];
            this.value_written = _data["value_written"];
        }
    };
    ContractResultStateChange.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ContractResultStateChange();
        result.init(data);
        return result;
    };
    ContractResultStateChange.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["address"] = this.address;
        data["contract_id"] = this.contract_id;
        data["slot"] = this.slot;
        data["value_read"] = this.value_read;
        data["value_written"] = this.value_written;
        return data;
    };
    return ContractResultStateChange;
}());
export { ContractResultStateChange };
var CustomFees = (function () {
    function CustomFees(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    CustomFees.prototype.init = function (_data) {
        if (_data) {
            this.created_timestamp = _data["created_timestamp"];
            if (Array.isArray(_data["fixed_fees"])) {
                this.fixed_fees = [];
                for (var _i = 0, _a = _data["fixed_fees"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.fixed_fees.push(FixedFee.fromJS(item));
                }
            }
            if (Array.isArray(_data["fractional_fees"])) {
                this.fractional_fees = [];
                for (var _b = 0, _c = _data["fractional_fees"]; _b < _c.length; _b++) {
                    var item = _c[_b];
                    this.fractional_fees.push(FractionalFee.fromJS(item));
                }
            }
            if (Array.isArray(_data["royalty_fees"])) {
                this.royalty_fees = [];
                for (var _d = 0, _e = _data["royalty_fees"]; _d < _e.length; _d++) {
                    var item = _e[_d];
                    this.royalty_fees.push(RoyaltyFee.fromJS(item));
                }
            }
        }
    };
    CustomFees.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new CustomFees();
        result.init(data);
        return result;
    };
    CustomFees.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["created_timestamp"] = this.created_timestamp;
        if (Array.isArray(this.fixed_fees)) {
            data["fixed_fees"] = [];
            for (var _i = 0, _a = this.fixed_fees; _i < _a.length; _i++) {
                var item = _a[_i];
                data["fixed_fees"].push(item.toJSON());
            }
        }
        if (Array.isArray(this.fractional_fees)) {
            data["fractional_fees"] = [];
            for (var _b = 0, _c = this.fractional_fees; _b < _c.length; _b++) {
                var item = _c[_b];
                data["fractional_fees"].push(item.toJSON());
            }
        }
        if (Array.isArray(this.royalty_fees)) {
            data["royalty_fees"] = [];
            for (var _d = 0, _e = this.royalty_fees; _d < _e.length; _d++) {
                var item = _e[_d];
                data["royalty_fees"].push(item.toJSON());
            }
        }
        return data;
    };
    return CustomFees;
}());
export { CustomFees };
var CryptoAllowance = (function (_super) {
    __extends(CryptoAllowance, _super);
    function CryptoAllowance(data) {
        return _super.call(this, data) || this;
    }
    CryptoAllowance.prototype.init = function (_data) {
        _super.prototype.init.call(this, _data);
        if (_data) {
            this.amount_granted = _data["amount_granted"];
        }
    };
    CryptoAllowance.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new CryptoAllowance();
        result.init(data);
        return result;
    };
    CryptoAllowance.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["amount_granted"] = this.amount_granted;
        _super.prototype.toJSON.call(this, data);
        return data;
    };
    return CryptoAllowance;
}(Allowance));
export { CryptoAllowance };
var ErrorDto = (function () {
    function ErrorDto(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ErrorDto.prototype.init = function (_data) {
        if (_data) {
            this._status = _data["_status"] ? _status.fromJS(_data["_status"]) : undefined;
        }
    };
    ErrorDto.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ErrorDto();
        result.init(data);
        return result;
    };
    ErrorDto.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["_status"] = this._status ? this._status.toJSON() : undefined;
        return data;
    };
    return ErrorDto;
}());
export { ErrorDto };
var ExchangeRate = (function () {
    function ExchangeRate(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ExchangeRate.prototype.init = function (_data) {
        if (_data) {
            this.cent_equivalent = _data["cent_equivalent"];
            this.expiration_time = _data["expiration_time"];
            this.hbar_equivalent = _data["hbar_equivalent"];
        }
    };
    ExchangeRate.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ExchangeRate();
        result.init(data);
        return result;
    };
    ExchangeRate.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["cent_equivalent"] = this.cent_equivalent;
        data["expiration_time"] = this.expiration_time;
        data["hbar_equivalent"] = this.hbar_equivalent;
        return data;
    };
    return ExchangeRate;
}());
export { ExchangeRate };
var FixedFee = (function () {
    function FixedFee(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    FixedFee.prototype.init = function (_data) {
        if (_data) {
            this.amount = _data["amount"];
            this.collector_account_id = _data["collector_account_id"];
            this.denominating_token_id = _data["denominating_token_id"];
        }
    };
    FixedFee.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new FixedFee();
        result.init(data);
        return result;
    };
    FixedFee.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["amount"] = this.amount;
        data["collector_account_id"] = this.collector_account_id;
        data["denominating_token_id"] = this.denominating_token_id;
        return data;
    };
    return FixedFee;
}());
export { FixedFee };
var FractionalFee = (function () {
    function FractionalFee(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    FractionalFee.prototype.init = function (_data) {
        if (_data) {
            this.amount = _data["amount"] ? Amount.fromJS(_data["amount"]) : undefined;
            this.collector_account_id = _data["collector_account_id"];
            this.denominating_token_id = _data["denominating_token_id"];
            this.maximum = _data["maximum"];
            this.minimum = _data["minimum"];
            this.net_of_transfers = _data["net_of_transfers"];
        }
    };
    FractionalFee.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new FractionalFee();
        result.init(data);
        return result;
    };
    FractionalFee.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["amount"] = this.amount ? this.amount.toJSON() : undefined;
        data["collector_account_id"] = this.collector_account_id;
        data["denominating_token_id"] = this.denominating_token_id;
        data["maximum"] = this.maximum;
        data["minimum"] = this.minimum;
        data["net_of_transfers"] = this.net_of_transfers;
        return data;
    };
    return FractionalFee;
}());
export { FractionalFee };
var RoyaltyFee = (function () {
    function RoyaltyFee(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    RoyaltyFee.prototype.init = function (_data) {
        if (_data) {
            this.amount = _data["amount"] ? Amount2.fromJS(_data["amount"]) : undefined;
            this.collector_account_id = _data["collector_account_id"];
            this.fallback_fee = _data["fallback_fee"] ? Fallback_fee.fromJS(_data["fallback_fee"]) : undefined;
        }
    };
    RoyaltyFee.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new RoyaltyFee();
        result.init(data);
        return result;
    };
    RoyaltyFee.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["amount"] = this.amount ? this.amount.toJSON() : undefined;
        data["collector_account_id"] = this.collector_account_id;
        data["fallback_fee"] = this.fallback_fee ? this.fallback_fee.toJSON() : undefined;
        return data;
    };
    return RoyaltyFee;
}());
export { RoyaltyFee };
var Key = (function () {
    function Key(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Key.prototype.init = function (_data) {
        if (_data) {
            this._type = _data["_type"];
            this.key = _data["key"];
        }
    };
    Key.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Key();
        result.init(data);
        return result;
    };
    Key.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["_type"] = this._type;
        data["key"] = this.key;
        return data;
    };
    return Key;
}());
export { Key };
var Links = (function () {
    function Links(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Links.prototype.init = function (_data) {
        if (_data) {
            this.next = _data["next"];
        }
    };
    Links.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Links();
        result.init(data);
        return result;
    };
    Links.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["next"] = this.next;
        return data;
    };
    return Links;
}());
export { Links };
var NetworkNode = (function () {
    function NetworkNode(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    NetworkNode.prototype.init = function (_data) {
        if (_data) {
            this.description = _data["description"];
            this.file_id = _data["file_id"];
            this.memo = _data["memo"];
            this.node_id = _data["node_id"];
            this.node_account_id = _data["node_account_id"];
            this.node_cert_hash = _data["node_cert_hash"];
            this.public_key = _data["public_key"];
            if (Array.isArray(_data["service_endpoints"])) {
                this.service_endpoints = [];
                for (var _i = 0, _a = _data["service_endpoints"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.service_endpoints.push(ServiceEndpoint.fromJS(item));
                }
            }
            this.timestamp = _data["timestamp"] ? TimestampRange.fromJS(_data["timestamp"]) : undefined;
        }
    };
    NetworkNode.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new NetworkNode();
        result.init(data);
        return result;
    };
    NetworkNode.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["description"] = this.description;
        data["file_id"] = this.file_id;
        data["memo"] = this.memo;
        data["node_id"] = this.node_id;
        data["node_account_id"] = this.node_account_id;
        data["node_cert_hash"] = this.node_cert_hash;
        data["public_key"] = this.public_key;
        if (Array.isArray(this.service_endpoints)) {
            data["service_endpoints"] = [];
            for (var _i = 0, _a = this.service_endpoints; _i < _a.length; _i++) {
                var item = _a[_i];
                data["service_endpoints"].push(item.toJSON());
            }
        }
        data["timestamp"] = this.timestamp ? this.timestamp.toJSON() : undefined;
        return data;
    };
    return NetworkNode;
}());
export { NetworkNode };
var NetworkFee = (function () {
    function NetworkFee(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    NetworkFee.prototype.init = function (_data) {
        if (_data) {
            this.gas = _data["gas"];
            this.transaction_type = _data["transaction_type"];
        }
    };
    NetworkFee.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new NetworkFee();
        result.init(data);
        return result;
    };
    NetworkFee.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["gas"] = this.gas;
        data["transaction_type"] = this.transaction_type;
        return data;
    };
    return NetworkFee;
}());
export { NetworkFee };
var Nft = (function () {
    function Nft(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Nft.prototype.init = function (_data) {
        if (_data) {
            this.account_id = _data["account_id"];
            this.created_timestamp = _data["created_timestamp"];
            this.delegating_spender = _data["delegating_spender"];
            this.deleted = _data["deleted"];
            this.metadata = _data["metadata"];
            this.modified_timestamp = _data["modified_timestamp"];
            this.serial_number = _data["serial_number"];
            this.spender = _data["spender"];
            this.token_id = _data["token_id"];
        }
    };
    Nft.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Nft();
        result.init(data);
        return result;
    };
    Nft.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["account_id"] = this.account_id;
        data["created_timestamp"] = this.created_timestamp;
        data["delegating_spender"] = this.delegating_spender;
        data["deleted"] = this.deleted;
        data["metadata"] = this.metadata;
        data["modified_timestamp"] = this.modified_timestamp;
        data["serial_number"] = this.serial_number;
        data["spender"] = this.spender;
        data["token_id"] = this.token_id;
        return data;
    };
    return Nft;
}());
export { Nft };
var Nfts = (function () {
    function Nfts(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Nfts.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["nfts"])) {
                this.nfts = [];
                for (var _i = 0, _a = _data["nfts"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.nfts.push(Nft.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    Nfts.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Nfts();
        result.init(data);
        return result;
    };
    Nfts.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.nfts)) {
            data["nfts"] = [];
            for (var _i = 0, _a = this.nfts; _i < _a.length; _i++) {
                var item = _a[_i];
                data["nfts"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return Nfts;
}());
export { Nfts };
var NftTransactionTransfer = (function () {
    function NftTransactionTransfer(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    NftTransactionTransfer.prototype.init = function (_data) {
        if (_data) {
            this.consensus_timestamp = _data["consensus_timestamp"];
            this.is_approval = _data["is_approval"];
            this.nonce = _data["nonce"];
            this.transaction_id = _data["transaction_id"];
            this.receiver_account_id = _data["receiver_account_id"];
            this.sender_account_id = _data["sender_account_id"];
            this.type = _data["type"];
            this.token_id = _data["token_id"];
        }
    };
    NftTransactionTransfer.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new NftTransactionTransfer();
        result.init(data);
        return result;
    };
    NftTransactionTransfer.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["consensus_timestamp"] = this.consensus_timestamp;
        data["is_approval"] = this.is_approval;
        data["nonce"] = this.nonce;
        data["transaction_id"] = this.transaction_id;
        data["receiver_account_id"] = this.receiver_account_id;
        data["sender_account_id"] = this.sender_account_id;
        data["type"] = this.type;
        data["token_id"] = this.token_id;
        return data;
    };
    return NftTransactionTransfer;
}());
export { NftTransactionTransfer };
var Block = (function () {
    function Block(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Block.prototype.init = function (_data) {
        if (_data) {
            this.count = _data["count"];
            this.gas_used = _data["gas_used"];
            this.hapi_version = _data["hapi_version"];
            this.hash = _data["hash"];
            this.logs_bloom = _data["logs_bloom"];
            this.name = _data["name"];
            this.number = _data["number"];
            this.previous_hash = _data["previous_hash"];
            this.size = _data["size"];
            this.timestamp = _data["timestamp"] ? Timestamp2.fromJS(_data["timestamp"]) : undefined;
        }
    };
    Block.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Block();
        result.init(data);
        return result;
    };
    Block.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["count"] = this.count;
        data["gas_used"] = this.gas_used;
        data["hapi_version"] = this.hapi_version;
        data["hash"] = this.hash;
        data["logs_bloom"] = this.logs_bloom;
        data["name"] = this.name;
        data["number"] = this.number;
        data["previous_hash"] = this.previous_hash;
        data["size"] = this.size;
        data["timestamp"] = this.timestamp ? this.timestamp.toJSON() : undefined;
        return data;
    };
    return Block;
}());
export { Block };
var NftTransactionHistory = (function () {
    function NftTransactionHistory(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    NftTransactionHistory.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["transactions"])) {
                this.transactions = [];
                for (var _i = 0, _a = _data["transactions"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.transactions.push(NftTransactionTransfer.fromJS(item));
                }
            }
            this.links = _data["links"] ? Links.fromJS(_data["links"]) : undefined;
        }
    };
    NftTransactionHistory.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new NftTransactionHistory();
        result.init(data);
        return result;
    };
    NftTransactionHistory.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.transactions)) {
            data["transactions"] = [];
            for (var _i = 0, _a = this.transactions; _i < _a.length; _i++) {
                var item = _a[_i];
                data["transactions"].push(item.toJSON());
            }
        }
        data["links"] = this.links ? this.links.toJSON() : undefined;
        return data;
    };
    return NftTransactionHistory;
}());
export { NftTransactionHistory };
var Schedule = (function () {
    function Schedule(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Schedule.prototype.init = function (_data) {
        if (_data) {
            this.admin_key = _data["admin_key"] ? Key.fromJS(_data["admin_key"]) : undefined;
            this.consensus_timestamp = _data["consensus_timestamp"];
            this.creator_account_id = _data["creator_account_id"];
            this.deleted = _data["deleted"];
            this.executed_timestamp = _data["executed_timestamp"];
            this.expiration_time = _data["expiration_time"];
            this.memo = _data["memo"];
            this.payer_account_id = _data["payer_account_id"];
            this.schedule_id = _data["schedule_id"];
            if (Array.isArray(_data["signatures"])) {
                this.signatures = [];
                for (var _i = 0, _a = _data["signatures"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.signatures.push(ScheduleSignature.fromJS(item));
                }
            }
            this.transaction_body = _data["transaction_body"];
            this.wait_for_expiry = _data["wait_for_expiry"];
        }
    };
    Schedule.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Schedule();
        result.init(data);
        return result;
    };
    Schedule.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["admin_key"] = this.admin_key ? this.admin_key.toJSON() : undefined;
        data["consensus_timestamp"] = this.consensus_timestamp;
        data["creator_account_id"] = this.creator_account_id;
        data["deleted"] = this.deleted;
        data["executed_timestamp"] = this.executed_timestamp;
        data["expiration_time"] = this.expiration_time;
        data["memo"] = this.memo;
        data["payer_account_id"] = this.payer_account_id;
        data["schedule_id"] = this.schedule_id;
        if (Array.isArray(this.signatures)) {
            data["signatures"] = [];
            for (var _i = 0, _a = this.signatures; _i < _a.length; _i++) {
                var item = _a[_i];
                data["signatures"].push(item.toJSON());
            }
        }
        data["transaction_body"] = this.transaction_body;
        data["wait_for_expiry"] = this.wait_for_expiry;
        return data;
    };
    return Schedule;
}());
export { Schedule };
var ScheduleSignature = (function () {
    function ScheduleSignature(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ScheduleSignature.prototype.init = function (_data) {
        if (_data) {
            this.consensus_timestmap = _data["consensus_timestmap"];
            this.public_key_prefix = _data["public_key_prefix"];
            this.signature = _data["signature"];
            this.type = _data["type"];
        }
    };
    ScheduleSignature.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ScheduleSignature();
        result.init(data);
        return result;
    };
    ScheduleSignature.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["consensus_timestmap"] = this.consensus_timestmap;
        data["public_key_prefix"] = this.public_key_prefix;
        data["signature"] = this.signature;
        data["type"] = this.type;
        return data;
    };
    return ScheduleSignature;
}());
export { ScheduleSignature };
var ServiceEndpoint = (function () {
    function ServiceEndpoint(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    ServiceEndpoint.prototype.init = function (_data) {
        if (_data) {
            this.ip_address_v4 = _data["ip_address_v4"];
            this.port = _data["port"];
        }
    };
    ServiceEndpoint.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new ServiceEndpoint();
        result.init(data);
        return result;
    };
    ServiceEndpoint.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["ip_address_v4"] = this.ip_address_v4;
        data["port"] = this.port;
        return data;
    };
    return ServiceEndpoint;
}());
export { ServiceEndpoint };
var StateProofFiles = (function () {
    function StateProofFiles(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    StateProofFiles.prototype.init = function (_data) {
        if (_data) {
            this.record_file = _data["record_file"];
            if (Array.isArray(_data["address_books"])) {
                this.address_books = [];
                for (var _i = 0, _a = _data["address_books"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.address_books.push(item);
                }
            }
            this.signature_files = _data["signature_files"] ? Signature_files.fromJS(_data["signature_files"]) : undefined;
        }
    };
    StateProofFiles.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new StateProofFiles();
        result.init(data);
        return result;
    };
    StateProofFiles.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["record_file"] = this.record_file;
        if (Array.isArray(this.address_books)) {
            data["address_books"] = [];
            for (var _i = 0, _a = this.address_books; _i < _a.length; _i++) {
                var item = _a[_i];
                data["address_books"].push(item);
            }
        }
        data["signature_files"] = this.signature_files ? this.signature_files.toJSON() : undefined;
        return data;
    };
    return StateProofFiles;
}());
export { StateProofFiles };
var TimestampRange = (function () {
    function TimestampRange(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    TimestampRange.prototype.init = function (_data) {
        if (_data) {
            this.from = _data["from"];
            this.to = _data["to"];
        }
    };
    TimestampRange.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TimestampRange();
        result.init(data);
        return result;
    };
    TimestampRange.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["from"] = this.from;
        data["to"] = this.to;
        return data;
    };
    return TimestampRange;
}());
export { TimestampRange };
var Token = (function () {
    function Token(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Token.prototype.init = function (_data) {
        if (_data) {
            this.token_id = _data["token_id"];
            this.symbol = _data["symbol"];
            this.admin_key = _data["admin_key"] ? Key.fromJS(_data["admin_key"]) : undefined;
            this.type = _data["type"];
        }
    };
    Token.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Token();
        result.init(data);
        return result;
    };
    Token.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["token_id"] = this.token_id;
        data["symbol"] = this.symbol;
        data["admin_key"] = this.admin_key ? this.admin_key.toJSON() : undefined;
        data["type"] = this.type;
        return data;
    };
    return Token;
}());
export { Token };
var TokenAllowance = (function (_super) {
    __extends(TokenAllowance, _super);
    function TokenAllowance(data) {
        return _super.call(this, data) || this;
    }
    TokenAllowance.prototype.init = function (_data) {
        _super.prototype.init.call(this, _data);
        if (_data) {
            this.token_id = _data["token_id"];
        }
    };
    TokenAllowance.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TokenAllowance();
        result.init(data);
        return result;
    };
    TokenAllowance.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["token_id"] = this.token_id;
        _super.prototype.toJSON.call(this, data);
        return data;
    };
    return TokenAllowance;
}(Allowance));
export { TokenAllowance };
var TokenBalance = (function () {
    function TokenBalance(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    TokenBalance.prototype.init = function (_data) {
        if (_data) {
            this.token_id = _data["token_id"];
            this.balance = _data["balance"];
        }
    };
    TokenBalance.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TokenBalance();
        result.init(data);
        return result;
    };
    TokenBalance.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["token_id"] = this.token_id;
        data["balance"] = this.balance;
        return data;
    };
    return TokenBalance;
}());
export { TokenBalance };
var TokenInfo = (function () {
    function TokenInfo(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    TokenInfo.prototype.init = function (_data) {
        if (_data) {
            this.admin_key = _data["admin_key"] ? Key.fromJS(_data["admin_key"]) : undefined;
            this.auto_renew_account = _data["auto_renew_account"];
            this.auto_renew_period = _data["auto_renew_period"];
            this.created_timestamp = _data["created_timestamp"];
            this.decimals = _data["decimals"];
            this.deleted = _data["deleted"];
            this.expiry_timestamp = _data["expiry_timestamp"];
            this.fee_schedule_key = _data["fee_schedule_key"] ? Key.fromJS(_data["fee_schedule_key"]) : undefined;
            this.freeze_default = _data["freeze_default"];
            this.freeze_key = _data["freeze_key"] ? Key.fromJS(_data["freeze_key"]) : undefined;
            this.initial_supply = _data["initial_supply"];
            this.kyc_key = _data["kyc_key"] ? Key.fromJS(_data["kyc_key"]) : undefined;
            this.max_supply = _data["max_supply"];
            this.modified_timestamp = _data["modified_timestamp"];
            this.name = _data["name"];
            this.memo = _data["memo"];
            this.pause_key = _data["pause_key"] ? Key.fromJS(_data["pause_key"]) : undefined;
            this.pause_status = _data["pause_status"];
            this.supply_key = _data["supply_key"] ? Key.fromJS(_data["supply_key"]) : undefined;
            this.supply_type = _data["supply_type"];
            this.symbol = _data["symbol"];
            this.token_id = _data["token_id"];
            this.total_supply = _data["total_supply"];
            this.treasury_account_id = _data["treasury_account_id"];
            this.type = _data["type"];
            this.wipe_key = _data["wipe_key"] ? Key.fromJS(_data["wipe_key"]) : undefined;
            this.custom_fees = _data["custom_fees"] ? CustomFees.fromJS(_data["custom_fees"]) : undefined;
        }
    };
    TokenInfo.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TokenInfo();
        result.init(data);
        return result;
    };
    TokenInfo.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["admin_key"] = this.admin_key ? this.admin_key.toJSON() : undefined;
        data["auto_renew_account"] = this.auto_renew_account;
        data["auto_renew_period"] = this.auto_renew_period;
        data["created_timestamp"] = this.created_timestamp;
        data["decimals"] = this.decimals;
        data["deleted"] = this.deleted;
        data["expiry_timestamp"] = this.expiry_timestamp;
        data["fee_schedule_key"] = this.fee_schedule_key ? this.fee_schedule_key.toJSON() : undefined;
        data["freeze_default"] = this.freeze_default;
        data["freeze_key"] = this.freeze_key ? this.freeze_key.toJSON() : undefined;
        data["initial_supply"] = this.initial_supply;
        data["kyc_key"] = this.kyc_key ? this.kyc_key.toJSON() : undefined;
        data["max_supply"] = this.max_supply;
        data["modified_timestamp"] = this.modified_timestamp;
        data["name"] = this.name;
        data["memo"] = this.memo;
        data["pause_key"] = this.pause_key ? this.pause_key.toJSON() : undefined;
        data["pause_status"] = this.pause_status;
        data["supply_key"] = this.supply_key ? this.supply_key.toJSON() : undefined;
        data["supply_type"] = this.supply_type;
        data["symbol"] = this.symbol;
        data["token_id"] = this.token_id;
        data["total_supply"] = this.total_supply;
        data["treasury_account_id"] = this.treasury_account_id;
        data["type"] = this.type;
        data["wipe_key"] = this.wipe_key ? this.wipe_key.toJSON() : undefined;
        data["custom_fees"] = this.custom_fees ? this.custom_fees.toJSON() : undefined;
        return data;
    };
    return TokenInfo;
}());
export { TokenInfo };
export var TransactionTypes;
(function (TransactionTypes) {
    TransactionTypes["CONSENSUSCREATETOPIC"] = "CONSENSUSCREATETOPIC";
    TransactionTypes["CONSENSUSDELETETOPIC"] = "CONSENSUSDELETETOPIC";
    TransactionTypes["CONSENSUSSUBMITMESSAGE"] = "CONSENSUSSUBMITMESSAGE";
    TransactionTypes["CONSENSUSUPDATETOPIC"] = "CONSENSUSUPDATETOPIC";
    TransactionTypes["CONTRACTCALL"] = "CONTRACTCALL";
    TransactionTypes["CONTRACTCREATEINSTANCE"] = "CONTRACTCREATEINSTANCE";
    TransactionTypes["CONTRACTDELETEINSTANCE"] = "CONTRACTDELETEINSTANCE";
    TransactionTypes["CONTRACTUPDATEINSTANCE"] = "CONTRACTUPDATEINSTANCE";
    TransactionTypes["CRYPTOADDLIVEHASH"] = "CRYPTOADDLIVEHASH";
    TransactionTypes["CRYPTOAPPROVEALLOWANCE"] = "CRYPTOAPPROVEALLOWANCE";
    TransactionTypes["CRYPTOCREATEACCOUNT"] = "CRYPTOCREATEACCOUNT";
    TransactionTypes["CRYPTODELETE"] = "CRYPTODELETE";
    TransactionTypes["CRYPTODELETEALLOWANCE"] = "CRYPTODELETEALLOWANCE";
    TransactionTypes["CRYPTODELETELIVEHASH"] = "CRYPTODELETELIVEHASH";
    TransactionTypes["CRYPTOTRANSFER"] = "CRYPTOTRANSFER";
    TransactionTypes["CRYPTOUPDATEACCOUNT"] = "CRYPTOUPDATEACCOUNT";
    TransactionTypes["ETHEREUMTRANSACTION"] = "ETHEREUMTRANSACTION";
    TransactionTypes["FILEAPPEND"] = "FILEAPPEND";
    TransactionTypes["FILECREATE"] = "FILECREATE";
    TransactionTypes["FILEDELETE"] = "FILEDELETE";
    TransactionTypes["FILEUPDATE"] = "FILEUPDATE";
    TransactionTypes["FREEZE"] = "FREEZE";
    TransactionTypes["NODESTAKEUPDATE"] = "NODESTAKEUPDATE";
    TransactionTypes["SCHEDULECREATE"] = "SCHEDULECREATE";
    TransactionTypes["SCHEDULEDELETE"] = "SCHEDULEDELETE";
    TransactionTypes["SCHEDULESIGN"] = "SCHEDULESIGN";
    TransactionTypes["SYSTEMDELETE"] = "SYSTEMDELETE";
    TransactionTypes["SYSTEMUNDELETE"] = "SYSTEMUNDELETE";
    TransactionTypes["TOKENASSOCIATE"] = "TOKENASSOCIATE";
    TransactionTypes["TOKENBURN"] = "TOKENBURN";
    TransactionTypes["TOKENCREATION"] = "TOKENCREATION";
    TransactionTypes["TOKENDELETION"] = "TOKENDELETION";
    TransactionTypes["TOKENDISSOCIATE"] = "TOKENDISSOCIATE";
    TransactionTypes["TOKENFEESCHEDULEUPDATE"] = "TOKENFEESCHEDULEUPDATE";
    TransactionTypes["TOKENFREEZE"] = "TOKENFREEZE";
    TransactionTypes["TOKENGRANTKYC"] = "TOKENGRANTKYC";
    TransactionTypes["TOKENMINT"] = "TOKENMINT";
    TransactionTypes["TOKENPAUSE"] = "TOKENPAUSE";
    TransactionTypes["TOKENREVOKEKYC"] = "TOKENREVOKEKYC";
    TransactionTypes["TOKENUNFREEZE"] = "TOKENUNFREEZE";
    TransactionTypes["TOKENUNPAUSE"] = "TOKENUNPAUSE";
    TransactionTypes["TOKENUPDATE"] = "TOKENUPDATE";
    TransactionTypes["TOKENWIPE"] = "TOKENWIPE";
    TransactionTypes["UNCHECKEDSUBMIT"] = "UNCHECKEDSUBMIT";
})(TransactionTypes || (TransactionTypes = {}));
var TopicMessage = (function () {
    function TopicMessage(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    TopicMessage.prototype.init = function (_data) {
        if (_data) {
            this.chunk_info = _data["chunk_info"] ? ChunkInfo.fromJS(_data["chunk_info"]) : undefined;
            this.consensus_timestamp = _data["consensus_timestamp"];
            this.message = _data["message"];
            this.payer_account_id = _data["payer_account_id"];
            this.running_hash = _data["running_hash"];
            this.running_hash_version = _data["running_hash_version"];
            this.sequence_number = _data["sequence_number"];
            this.topic_id = _data["topic_id"];
        }
    };
    TopicMessage.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TopicMessage();
        result.init(data);
        return result;
    };
    TopicMessage.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["chunk_info"] = this.chunk_info ? this.chunk_info.toJSON() : undefined;
        data["consensus_timestamp"] = this.consensus_timestamp;
        data["message"] = this.message;
        data["payer_account_id"] = this.payer_account_id;
        data["running_hash"] = this.running_hash;
        data["running_hash_version"] = this.running_hash_version;
        data["sequence_number"] = this.sequence_number;
        data["topic_id"] = this.topic_id;
        return data;
    };
    return TopicMessage;
}());
export { TopicMessage };
var Transaction = (function () {
    function Transaction(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Transaction.prototype.init = function (_data) {
        if (_data) {
            this.bytes = _data["bytes"];
            this.charged_tx_fee = _data["charged_tx_fee"];
            this.consensus_timestamp = _data["consensus_timestamp"];
            this.entity_id = _data["entity_id"];
            this.max_fee = _data["max_fee"];
            this.memo_base64 = _data["memo_base64"];
            this.name = _data["name"];
            this.node = _data["node"];
            this.nonce = _data["nonce"];
            this.parent_consensus_timestamp = _data["parent_consensus_timestamp"];
            this.result = _data["result"];
            this.scheduled = _data["scheduled"];
            if (Array.isArray(_data["token_transfers"])) {
                this.token_transfers = [];
                for (var _i = 0, _a = _data["token_transfers"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.token_transfers.push(Token_transfers.fromJS(item));
                }
            }
            this.transaction_hash = _data["transaction_hash"];
            this.transaction_id = _data["transaction_id"];
            if (Array.isArray(_data["transfers"])) {
                this.transfers = [];
                for (var _b = 0, _c = _data["transfers"]; _b < _c.length; _b++) {
                    var item = _c[_b];
                    this.transfers.push(Transfers.fromJS(item));
                }
            }
            this.valid_duration_seconds = _data["valid_duration_seconds"];
            this.valid_start_timestamp = _data["valid_start_timestamp"];
        }
    };
    Transaction.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Transaction();
        result.init(data);
        return result;
    };
    Transaction.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["bytes"] = this.bytes;
        data["charged_tx_fee"] = this.charged_tx_fee;
        data["consensus_timestamp"] = this.consensus_timestamp;
        data["entity_id"] = this.entity_id;
        data["max_fee"] = this.max_fee;
        data["memo_base64"] = this.memo_base64;
        data["name"] = this.name;
        data["node"] = this.node;
        data["nonce"] = this.nonce;
        data["parent_consensus_timestamp"] = this.parent_consensus_timestamp;
        data["result"] = this.result;
        data["scheduled"] = this.scheduled;
        if (Array.isArray(this.token_transfers)) {
            data["token_transfers"] = [];
            for (var _i = 0, _a = this.token_transfers; _i < _a.length; _i++) {
                var item = _a[_i];
                data["token_transfers"].push(item.toJSON());
            }
        }
        data["transaction_hash"] = this.transaction_hash;
        data["transaction_id"] = this.transaction_id;
        if (Array.isArray(this.transfers)) {
            data["transfers"] = [];
            for (var _b = 0, _c = this.transfers; _b < _c.length; _b++) {
                var item = _c[_b];
                data["transfers"].push(item.toJSON());
            }
        }
        data["valid_duration_seconds"] = this.valid_duration_seconds;
        data["valid_start_timestamp"] = this.valid_start_timestamp;
        return data;
    };
    return Transaction;
}());
export { Transaction };
var TransactionDetail = (function (_super) {
    __extends(TransactionDetail, _super);
    function TransactionDetail(data) {
        return _super.call(this, data) || this;
    }
    TransactionDetail.prototype.init = function (_data) {
        _super.prototype.init.call(this, _data);
        if (_data) {
            if (Array.isArray(_data["assessed_custom_fees"])) {
                this.assessed_custom_fees = [];
                for (var _i = 0, _a = _data["assessed_custom_fees"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.assessed_custom_fees.push(Assessed_custom_fees.fromJS(item));
                }
            }
            if (Array.isArray(_data["nft_transfers"])) {
                this.nft_transfers = [];
                for (var _b = 0, _c = _data["nft_transfers"]; _b < _c.length; _b++) {
                    var item = _c[_b];
                    this.nft_transfers.push(Nft_transfers.fromJS(item));
                }
            }
        }
    };
    TransactionDetail.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TransactionDetail();
        result.init(data);
        return result;
    };
    TransactionDetail.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.assessed_custom_fees)) {
            data["assessed_custom_fees"] = [];
            for (var _i = 0, _a = this.assessed_custom_fees; _i < _a.length; _i++) {
                var item = _a[_i];
                data["assessed_custom_fees"].push(item.toJSON());
            }
        }
        if (Array.isArray(this.nft_transfers)) {
            data["nft_transfers"] = [];
            for (var _b = 0, _c = this.nft_transfers; _b < _c.length; _b++) {
                var item = _c[_b];
                data["nft_transfers"].push(item.toJSON());
            }
        }
        _super.prototype.toJSON.call(this, data);
        return data;
    };
    return TransactionDetail;
}(Transaction));
export { TransactionDetail };
var TransactionId = (function () {
    function TransactionId(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    TransactionId.prototype.init = function (_data) {
        if (_data) {
            this.account_id = _data["account_id"];
            this.nonce = _data["nonce"];
            this.scheduled = _data["scheduled"];
            this.transaction_valid_start = _data["transaction_valid_start"];
        }
    };
    TransactionId.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new TransactionId();
        result.init(data);
        return result;
    };
    TransactionId.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["account_id"] = this.account_id;
        data["nonce"] = this.nonce;
        data["scheduled"] = this.scheduled;
        data["transaction_valid_start"] = this.transaction_valid_start;
        return data;
    };
    return TransactionId;
}());
export { TransactionId };
export var Order;
(function (Order) {
    Order["Asc"] = "asc";
    Order["Desc"] = "desc";
})(Order || (Order = {}));
export var Result;
(function (Result) {
    Result["Success"] = "success";
    Result["Fail"] = "fail";
})(Result || (Result = {}));
export var Type;
(function (Type) {
    Type["Credit"] = "credit";
    Type["Debit"] = "debit";
})(Type || (Type = {}));
var Tokens = (function () {
    function Tokens(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Tokens.prototype.init = function (_data) {
        if (_data) {
            this.token_id = _data["token_id"];
            this.balance = _data["balance"];
        }
    };
    Tokens.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Tokens();
        result.init(data);
        return result;
    };
    Tokens.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["token_id"] = this.token_id;
        data["balance"] = this.balance;
        return data;
    };
    return Tokens;
}());
export { Tokens };
var _status = (function () {
    function _status(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    _status.prototype.init = function (_data) {
        if (_data) {
            if (Array.isArray(_data["messages"])) {
                this.messages = [];
                for (var _i = 0, _a = _data["messages"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.messages.push(Messages.fromJS(item));
                }
            }
        }
    };
    _status.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new _status();
        result.init(data);
        return result;
    };
    _status.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.messages)) {
            data["messages"] = [];
            for (var _i = 0, _a = this.messages; _i < _a.length; _i++) {
                var item = _a[_i];
                data["messages"].push(item.toJSON());
            }
        }
        return data;
    };
    return _status;
}());
export { _status };
var Amount = (function () {
    function Amount(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Amount.prototype.init = function (_data) {
        if (_data) {
            this.numerator = _data["numerator"];
            this.denominator = _data["denominator"];
        }
    };
    Amount.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Amount();
        result.init(data);
        return result;
    };
    Amount.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["numerator"] = this.numerator;
        data["denominator"] = this.denominator;
        return data;
    };
    return Amount;
}());
export { Amount };
var Amount2 = (function () {
    function Amount2(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Amount2.prototype.init = function (_data) {
        if (_data) {
            this.numerator = _data["numerator"];
            this.denominator = _data["denominator"];
        }
    };
    Amount2.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Amount2();
        result.init(data);
        return result;
    };
    Amount2.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["numerator"] = this.numerator;
        data["denominator"] = this.denominator;
        return data;
    };
    return Amount2;
}());
export { Amount2 };
var Fallback_fee = (function () {
    function Fallback_fee(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Fallback_fee.prototype.init = function (_data) {
        if (_data) {
            this.amount = _data["amount"];
            this.denominating_token_id = _data["denominating_token_id"];
        }
    };
    Fallback_fee.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Fallback_fee();
        result.init(data);
        return result;
    };
    Fallback_fee.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["amount"] = this.amount;
        data["denominating_token_id"] = this.denominating_token_id;
        return data;
    };
    return Fallback_fee;
}());
export { Fallback_fee };
export var Key_type;
(function (Key_type) {
    Key_type["ECDSA_SECP256K1"] = "ECDSA_SECP256K1";
    Key_type["ED25519"] = "ED25519";
    Key_type["ProtobufEncoded"] = "ProtobufEncoded";
})(Key_type || (Key_type = {}));
var Timestamp2 = (function () {
    function Timestamp2(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Timestamp2.prototype.init = function (_data) {
        if (_data) {
            this.from = _data["from"] ? TimestampRange.fromJS(_data["from"]) : undefined;
            this.to = _data["to"] ? TimestampRange.fromJS(_data["to"]) : undefined;
        }
    };
    Timestamp2.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Timestamp2();
        result.init(data);
        return result;
    };
    Timestamp2.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["from"] = this.from ? this.from.toJSON() : undefined;
        data["to"] = this.to ? this.to.toJSON() : undefined;
        return data;
    };
    return Timestamp2;
}());
export { Timestamp2 };
export var ScheduleSignatureType;
(function (ScheduleSignatureType) {
    ScheduleSignatureType["CONTRACT"] = "CONTRACT";
    ScheduleSignatureType["ED25519"] = "ED25519";
    ScheduleSignatureType["RSA_3072"] = "RSA_3072";
    ScheduleSignatureType["ECDSA_384"] = "ECDSA_384";
    ScheduleSignatureType["ECDSA_SECP256K1"] = "ECDSA_SECP256K1";
    ScheduleSignatureType["UNKNOWN"] = "UNKNOWN";
})(ScheduleSignatureType || (ScheduleSignatureType = {}));
var Signature_files = (function () {
    function Signature_files(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Signature_files.prototype.init = function (_data) {
        if (_data) {
            this._003 = _data["0.0.3"];
            this._004 = _data["0.0.4"];
            this._005 = _data["0.0.5"];
            this._006 = _data["0.0.6"];
        }
    };
    Signature_files.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Signature_files();
        result.init(data);
        return result;
    };
    Signature_files.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["0.0.3"] = this._003;
        data["0.0.4"] = this._004;
        data["0.0.5"] = this._005;
        data["0.0.6"] = this._006;
        return data;
    };
    return Signature_files;
}());
export { Signature_files };
export var TokenInfoPause_status;
(function (TokenInfoPause_status) {
    TokenInfoPause_status["NOT_APPLICABLE"] = "NOT_APPLICABLE";
    TokenInfoPause_status["PAUSED"] = "PAUSED";
    TokenInfoPause_status["UNPAUSED"] = "UNPAUSED";
})(TokenInfoPause_status || (TokenInfoPause_status = {}));
export var TokenInfoSupply_type;
(function (TokenInfoSupply_type) {
    TokenInfoSupply_type["FINITE"] = "FINITE";
    TokenInfoSupply_type["INFINITE"] = "INFINITE";
})(TokenInfoSupply_type || (TokenInfoSupply_type = {}));
export var TokenInfoType;
(function (TokenInfoType) {
    TokenInfoType["FUNGIBLE_COMMON"] = "FUNGIBLE_COMMON";
    TokenInfoType["NON_FUNGIBLE_UNIQUE"] = "NON_FUNGIBLE_UNIQUE";
})(TokenInfoType || (TokenInfoType = {}));
var Token_transfers = (function () {
    function Token_transfers(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Token_transfers.prototype.init = function (_data) {
        if (_data) {
            this.token_id = _data["token_id"];
            this.account = _data["account"];
            this.amount = _data["amount"];
            this.is_approval = _data["is_approval"];
        }
    };
    Token_transfers.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Token_transfers();
        result.init(data);
        return result;
    };
    Token_transfers.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["token_id"] = this.token_id;
        data["account"] = this.account;
        data["amount"] = this.amount;
        data["is_approval"] = this.is_approval;
        return data;
    };
    return Token_transfers;
}());
export { Token_transfers };
var Transfers = (function () {
    function Transfers(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Transfers.prototype.init = function (_data) {
        if (_data) {
            this.account = _data["account"];
            this.amount = _data["amount"];
            this.is_approval = _data["is_approval"];
        }
    };
    Transfers.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Transfers();
        result.init(data);
        return result;
    };
    Transfers.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["account"] = this.account;
        data["amount"] = this.amount;
        data["is_approval"] = this.is_approval;
        return data;
    };
    return Transfers;
}());
export { Transfers };
var Assessed_custom_fees = (function () {
    function Assessed_custom_fees(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Assessed_custom_fees.prototype.init = function (_data) {
        if (_data) {
            this.amount = _data["amount"];
            this.collector_account_id = _data["collector_account_id"];
            if (Array.isArray(_data["effective_payer_account_ids"])) {
                this.effective_payer_account_ids = [];
                for (var _i = 0, _a = _data["effective_payer_account_ids"]; _i < _a.length; _i++) {
                    var item = _a[_i];
                    this.effective_payer_account_ids.push(item);
                }
            }
            this.token_id = _data["token_id"];
        }
    };
    Assessed_custom_fees.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Assessed_custom_fees();
        result.init(data);
        return result;
    };
    Assessed_custom_fees.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["amount"] = this.amount;
        data["collector_account_id"] = this.collector_account_id;
        if (Array.isArray(this.effective_payer_account_ids)) {
            data["effective_payer_account_ids"] = [];
            for (var _i = 0, _a = this.effective_payer_account_ids; _i < _a.length; _i++) {
                var item = _a[_i];
                data["effective_payer_account_ids"].push(item);
            }
        }
        data["token_id"] = this.token_id;
        return data;
    };
    return Assessed_custom_fees;
}());
export { Assessed_custom_fees };
var Nft_transfers = (function () {
    function Nft_transfers(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Nft_transfers.prototype.init = function (_data) {
        if (_data) {
            this.is_approval = _data["is_approval"];
            this.receiver_account_id = _data["receiver_account_id"];
            this.sender_account_id = _data["sender_account_id"];
            this.serial_number = _data["serial_number"];
            this.token_id = _data["token_id"];
        }
    };
    Nft_transfers.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Nft_transfers();
        result.init(data);
        return result;
    };
    Nft_transfers.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["is_approval"] = this.is_approval;
        data["receiver_account_id"] = this.receiver_account_id;
        data["sender_account_id"] = this.sender_account_id;
        data["serial_number"] = this.serial_number;
        data["token_id"] = this.token_id;
        return data;
    };
    return Nft_transfers;
}());
export { Nft_transfers };
var Messages = (function () {
    function Messages(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    Messages.prototype.init = function (_data) {
        if (_data) {
            this.message = _data["message"];
        }
    };
    Messages.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new Messages();
        result.init(data);
        return result;
    };
    Messages.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data["message"] = this.message;
        return data;
    };
    return Messages;
}());
export { Messages };
var SwaggerResponse = (function () {
    function SwaggerResponse(status, headers, result) {
        this.status = status;
        this.headers = headers;
        this.result = result;
    }
    return SwaggerResponse;
}());
export { SwaggerResponse };
var SwaggerException = (function (_super) {
    __extends(SwaggerException, _super);
    function SwaggerException(message, status, response, headers, result) {
        var _this = _super.call(this) || this;
        _this.isSwaggerException = true;
        _this.message = message;
        _this.status = status;
        _this.response = response;
        _this.headers = headers;
        _this.result = result;
        return _this;
    }
    SwaggerException.isSwaggerException = function (obj) {
        return obj.isSwaggerException === true;
    };
    return SwaggerException;
}(Error));
export { SwaggerException };
function throwException(message, status, response, headers, result) {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new SwaggerException(message, status, response, headers, null);
}
