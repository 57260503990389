var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _ from "lodash";
import { decodeBase64 } from "../utils";
import { HederaMirrorNodeAPIClient, Order, TransactionTypes } from "./hedera-mirror-node-api-client";
import BPromise from 'bluebird';
import moment from 'moment';
export var client = new HederaMirrorNodeAPIClient();
export var ipfsGateways = [
    'https://dweb.link/ipfs/',
    'https://cf-ipfs.com/ipfs/',
    'https://gateway.ipfs.io/ipfs/',
];
export var fromIpfsProtocolToUrl = function (ipfs, ipfsGatewayIndex) {
    if (ipfsGatewayIndex === void 0) { ipfsGatewayIndex = 0; }
    return ipfsGateways[ipfsGatewayIndex] + ipfs.substring(7);
};
export var fromIpfsIdToUrl = function (ipfs, ipfsGatewayIndex) {
    if (ipfsGatewayIndex === void 0) { ipfsGatewayIndex = 0; }
    return ipfsGateways[ipfsGatewayIndex] + ipfs;
};
export var fetchJson = function (url) { return fetch(url).then(function (response) {
    if (response.status !== 200) {
        throw new Error("Unsuccessful");
    }
    return response.json();
}); };
export var fetchArrayBuffer = function (url) { return fetch(url).then(function (response) {
    if (response.status !== 200) {
        throw new Error("Unsuccessful");
    }
    response.arrayBuffer();
}); };
export var getNftierUrl = function (nftierPath, sn) { return "https://nftier.tech/hedera/".concat(nftierPath, "/").concat(sn); };
var tokenInfo = new Map();
export var getTokenInfo = function (tokenId) { return __awaiter(void 0, void 0, Promise, function () {
    var promise;
    return __generator(this, function (_a) {
        if (!tokenInfo.has(tokenId)) {
            promise = client.getTokenById(tokenId, undefined).then(function (response) { return response.result; });
            tokenInfo.set(tokenId, promise);
        }
        return [2, tokenInfo.get(tokenId)];
    });
}); };
export var getThisMonthsIncome = function (accountId) { return __awaiter(void 0, void 0, void 0, function () {
    var now, startTs, endTs, response, trans, fetchResult;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                now = function () { return moment.utc(); };
                startTs = "".concat(now().startOf('month').toDate().valueOf() / 1000);
                endTs = "".concat(now().startOf('month').add(1, 'month').toDate().valueOf() / 1000);
                return [4, client.listTransactions(accountId, 100, Order.Asc, ["gte:".concat(startTs), "lt:".concat(endTs)], TransactionTypes.CRYPTOTRANSFER, undefined, undefined)];
            case 1:
                response = _b.sent();
                trans = [];
                if (response.result.transactions && response.result.transactions.length > 0) {
                    trans.push.apply(trans, response.result.transactions);
                }
                _b.label = 2;
            case 2:
                if (!((_a = response.result.links) === null || _a === void 0 ? void 0 : _a.next)) return [3, 5];
                return [4, fetch(client.baseUrl + response.result.links.next)];
            case 3:
                fetchResult = _b.sent();
                return [4, client.processListTransactions(fetchResult)];
            case 4:
                response = _b.sent();
                if (response.result.transactions && response.result.transactions.length > 0) {
                    trans.push.apply(trans, response.result.transactions);
                }
                return [3, 2];
            case 5: return [2, trans];
        }
    });
}); };
export var getStakingRewardTransactions = function (accountId, daysAgo) { return __awaiter(void 0, void 0, void 0, function () {
    var now, startTs, endTs, response, trans, fetchResult;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                now = function () { return moment.utc(); };
                startTs = "".concat(now().subtract(daysAgo, 'days').toDate().valueOf() / 1000);
                endTs = "".concat(now().toDate().valueOf() / 1000);
                return [4, client.listTransactions(accountId, 100, Order.Desc, ["gte:".concat(startTs), "lt:".concat(endTs)], undefined, undefined, undefined)];
            case 1:
                response = _b.sent();
                trans = [];
                if (response.result.transactions && response.result.transactions.length > 0) {
                    trans.push.apply(trans, response.result.transactions);
                }
                _b.label = 2;
            case 2:
                if (!((_a = response.result.links) === null || _a === void 0 ? void 0 : _a.next)) return [3, 5];
                return [4, fetch(client.baseUrl + response.result.links.next)];
            case 3:
                fetchResult = _b.sent();
                return [4, client.processListTransactions(fetchResult)];
            case 4:
                response = _b.sent();
                if (response.result.transactions && response.result.transactions.length > 0) {
                    trans.push.apply(trans, response.result.transactions);
                }
                return [3, 2];
            case 5: return [2, trans.filter(function (o) { var _a; return (_a = o.transfers) === null || _a === void 0 ? void 0 : _a.some(function (q) { return q.account === '0.0.800' && q.amount < 0; }); })];
        }
    });
}); };
var nftInfo = new Map();
export var getNftInfo = function (tokenId, serialNumber) { return __awaiter(void 0, void 0, Promise, function () {
    var nftId, promise;
    return __generator(this, function (_a) {
        nftId = "".concat(tokenId, ":").concat(serialNumber);
        if (!nftInfo.has(nftId)) {
            promise = client.listNftBySerialnumber(tokenId, serialNumber).then(function (response) { return response.result; });
            nftInfo.set(nftId, promise);
        }
        return [2, nftInfo.get(nftId)];
    });
}); };
export var executeWithRetriesAsync = function (func_1, shouldRetry_1) {
    var args_1 = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args_1[_i - 2] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([func_1, shouldRetry_1], args_1, true), Promise, function (func, shouldRetry, maxRetries) {
        var retryNum, err_1;
        if (maxRetries === void 0) { maxRetries = 5; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    retryNum = 0;
                    _a.label = 1;
                case 1:
                    if (!(maxRetries > 0)) return [3, 6];
                    maxRetries--;
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4, func(retryNum)];
                case 3: return [2, _a.sent()];
                case 4:
                    err_1 = _a.sent();
                    if (maxRetries <= 0 || !shouldRetry(err_1)) {
                        throw err_1;
                    }
                    retryNum++;
                    return [3, 5];
                case 5: return [3, 1];
                case 6: throw new Error("Reached maximum retries and did not rethrow error... Should not have gotten here.");
            }
        });
    });
};
var metadataCache = new Map();
export var getMetadataObj = function (metadata) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!metadataCache.has(metadata)) {
            metadataCache.set(metadata, new Promise(function (resolveOuter, rejectOuter) { return __awaiter(void 0, void 0, void 0, function () {
                var metadataIpfs, metadataObj, err_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            metadataIpfs = decodeBase64(metadata);
                            if (!metadataIpfs || metadataIpfs.length <= 20) {
                                throw new Error("NFT Metadata is invalid");
                            }
                            if (!metadataIpfs.startsWith('ipfs://') && !metadataIpfs.startsWith('https://')) {
                                metadataIpfs = "ipfs://".concat(metadataIpfs);
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4, new BPromise(function (resolve) { return executeWithRetriesAsync(function (retry) { return __awaiter(void 0, void 0, void 0, function () {
                                    var metadataUrl, json;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                metadataUrl = metadataIpfs;
                                                if (!metadataIpfs.startsWith('https://')) {
                                                    metadataUrl = fromIpfsProtocolToUrl(metadataIpfs, retry % ipfsGateways.length);
                                                }
                                                return [4, fetchJson(metadataUrl)];
                                            case 1:
                                                json = _a.sent();
                                                resolve(json);
                                                return [2];
                                        }
                                    });
                                }); }, function () { return true; }); }).timeout(30000, 'Error, request timeout. IPFS data could not be loaded')];
                        case 2:
                            metadataObj = _a.sent();
                            resolveOuter(metadataObj);
                            return [3, 4];
                        case 3:
                            err_2 = _a.sent();
                            rejectOuter(err_2);
                            return [3, 4];
                        case 4: return [2];
                    }
                });
            }); }));
        }
        return [2, metadataCache.get(metadata)];
    });
}); };
export var listAccounts = function (idGt_1, idLt_1) {
    var args_1 = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args_1[_i - 2] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([idGt_1, idLt_1], args_1, true), void 0, function (idGt, idLt, limit) {
        var params, response, processedResponse;
        if (limit === void 0) { limit = 100; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = new URLSearchParams();
                    if (idGt) {
                        params.append("account.id", "gte:0.0.".concat(idGt));
                    }
                    if (idLt) {
                        params.append("account.id", "lt:0.0.".concat(idLt));
                    }
                    if (limit) {
                        params.append("limit", "".concat(limit));
                    }
                    return [4, fetch("".concat(client.baseUrl, "/api/v1/accounts?").concat(params.toString()))];
                case 1:
                    response = _a.sent();
                    return [4, client.processListAccounts(response)];
                case 2:
                    processedResponse = _a.sent();
                    return [2, processedResponse.result];
            }
        });
    });
};
export var getAccount = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var params, response, processedResponse;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                params = new URLSearchParams();
                if (id) {
                    params.append("account.id", "eq:0.0.".concat(id));
                }
                return [4, fetch("".concat(client.baseUrl, "/api/v1/accounts?").concat(params.toString()))];
            case 1:
                response = _a.sent();
                return [4, client.processListAccounts(response)];
            case 2:
                processedResponse = _a.sent();
                return [2, processedResponse.result];
        }
    });
}); };
export var getMostRecentNFTs = function () {
    var args_1 = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args_1[_i] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (limit) {
        var response;
        if (limit === void 0) { limit = 100; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, client.listTokens(undefined, undefined, ["NON_FUNGIBLE_UNIQUE"], limit, undefined, Order.Desc)];
                case 1:
                    response = _a.sent();
                    return [2, response.result];
            }
        });
    });
};
export var listNftTokens = function (idGt_1, idLt_1) {
    var args_1 = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args_1[_i - 2] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([idGt_1, idLt_1], args_1, true), void 0, function (idGt, idLt, limit) {
        var params, response, processedResponse;
        if (limit === void 0) { limit = 100; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = new URLSearchParams();
                    params.append("type", "NON_FUNGIBLE_UNIQUE");
                    if (idGt) {
                        params.append("token.id", "gt:0.0.".concat(idGt));
                    }
                    if (idLt) {
                        params.append("token.id", "lt:0.0.".concat(idLt));
                    }
                    if (limit) {
                        params.append("limit", "".concat(limit));
                    }
                    return [4, fetch("".concat(client.baseUrl, "/api/v1/tokens?").concat(params.toString()))];
                case 1:
                    response = _a.sent();
                    return [4, client.processListTokens(response)];
                case 2:
                    processedResponse = _a.sent();
                    return [2, processedResponse.result];
            }
        });
    });
};
var queryUntilEnd = function (queryFunc_1, processQueryFunc_1) {
    var args_1 = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args_1[_i - 2] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([queryFunc_1, processQueryFunc_1], args_1, true), Promise, function (queryFunc, processQueryFunc, maxRequests) {
        var results, response, requests, _a;
        var _b;
        if (maxRequests === void 0) { maxRequests = 500; }
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    results = [];
                    return [4, queryFunc()];
                case 1:
                    response = _c.sent();
                    if (response.result) {
                        results.push(response.result);
                    }
                    requests = 0;
                    _c.label = 2;
                case 2:
                    if (!(requests < maxRequests && ((_b = response.result.links) === null || _b === void 0 ? void 0 : _b.next))) return [3, 5];
                    _a = processQueryFunc;
                    return [4, fetch(client.baseUrl + response.result.links.next)];
                case 3: return [4, _a.apply(void 0, [_c.sent()])];
                case 4:
                    response = _c.sent();
                    if (response.result) {
                        results.push(response.result);
                    }
                    requests++;
                    return [3, 2];
                case 5: return [2, results];
            }
        });
    });
};
export var listAllNfts = function (tokenId, maxRequests) { return __awaiter(void 0, void 0, void 0, function () {
    var queryFunc, results, nfts;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                queryFunc = function () { return client.listNfts(tokenId, undefined, 100, Order.Asc); };
                return [4, queryUntilEnd(queryFunc, function (res) { return client.processListNfts(res); }, maxRequests)];
            case 1:
                results = _a.sent();
                nfts = results.map(function (o) { var _a; return (_a = o.nfts) === null || _a === void 0 ? void 0 : _a.filter(function (o) { return !!o; }); }).flat();
                nfts.forEach(function (nft) {
                    var nftId = "".concat(nft.token_id, ":").concat(nft.serial_number);
                    if (!nftInfo.has(nftId)) {
                        nftInfo.set(nftId, Promise.resolve(nft));
                    }
                });
                return [2, nfts.filter(function (o) { return !o.deleted; })];
        }
    });
}); };
export var getAccountInfo = function (accountId) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, client.getAccountByIdOrAliasOrEvmAddress(accountId, undefined)];
            case 1:
                response = _a.sent();
                return [2, response.result];
        }
    });
}); };
export var listAllNftsForAccount = function (accountId, maxRequests) { return __awaiter(void 0, void 0, void 0, function () {
    var queryFunc, results, nfts;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                queryFunc = function () { return client.listNftByAccountId(accountId, undefined, undefined, undefined, 100, Order.Asc); };
                return [4, queryUntilEnd(queryFunc, function (res) { return client.processListNftByAccountId(res); }, maxRequests)];
            case 1:
                results = _a.sent();
                nfts = results.map(function (o) { var _a; return (_a = o.nfts) === null || _a === void 0 ? void 0 : _a.filter(function (o) { return !!o; }); }).flat();
                nfts.forEach(function (nft) {
                    var nftId = "".concat(nft.token_id, ":").concat(nft.serial_number);
                    if (!nftInfo.has(nftId)) {
                        nftInfo.set(nftId, Promise.resolve(nft));
                    }
                });
                return [2, nfts.filter(function (o) { return !o.deleted; })];
        }
    });
}); };
export var getAccountFirstNft = function (accountId) { return __awaiter(void 0, void 0, void 0, function () {
    var nfts, firstNft, metadataObj, tokenInfo_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, client.listNftByAccountId(accountId, undefined, undefined, undefined, 100, undefined)];
            case 1:
                nfts = _a.sent();
                if (!(nfts.result.nfts && nfts.result.nfts.length > 0)) return [3, 5];
                firstNft = nfts.result.nfts[0];
                metadataObj = null;
                if (!firstNft.metadata) return [3, 3];
                return [4, getMetadataObj(firstNft.metadata).catch(function () { return null; })];
            case 2:
                metadataObj = _a.sent();
                _a.label = 3;
            case 3: return [4, getTokenInfo(firstNft.token_id)];
            case 4:
                tokenInfo_1 = _a.sent();
                return [2, __assign(__assign({}, firstNft), { metadataObj: metadataObj, tokenInfo: tokenInfo_1 })];
            case 5: return [2, null];
        }
    });
}); };
export var getFirstNft = function (tokenId) { return __awaiter(void 0, void 0, void 0, function () {
    var response, nftInfo_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, client.listNfts(tokenId, undefined, 1, Order.Asc)];
            case 1:
                response = _a.sent();
                if (response.result.nfts && response.result.nfts.length > 0) {
                    nftInfo_1 = response.result.nfts[0];
                    return [2, nftInfo_1];
                }
                return [2, null];
        }
    });
}); };
export var getFirstNftWithMetadata = function (tokenId) { return __awaiter(void 0, void 0, void 0, function () {
    var nftInfo, metadataObj, tokenInfo_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4, getFirstNft(tokenId)];
            case 1:
                nftInfo = _a.sent();
                if (!nftInfo) return [3, 5];
                metadataObj = null;
                if (!nftInfo.metadata) return [3, 3];
                return [4, getMetadataObj(nftInfo.metadata).catch(function () { return null; })];
            case 2:
                metadataObj = _a.sent();
                _a.label = 3;
            case 3: return [4, getTokenInfo(nftInfo.token_id)];
            case 4:
                tokenInfo_2 = _a.sent();
                return [2, __assign(__assign({}, nftInfo), { metadataObj: metadataObj, tokenInfo: tokenInfo_2 })];
            case 5: return [2, null];
        }
    });
}); };
export var loadMetadataForNfts = function (nfts, onChunkLoaded) { return __awaiter(void 0, void 0, Promise, function () {
    var allNfts, nftsChunked, _i, nftsChunked_1, chunk, nftMetadataPromises, nftMetadatas, nftMetadatasFiltered;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                allNfts = [];
                if (!nfts) return [3, 4];
                nftsChunked = _.chunk(nfts, 100);
                _i = 0, nftsChunked_1 = nftsChunked;
                _a.label = 1;
            case 1:
                if (!(_i < nftsChunked_1.length)) return [3, 4];
                chunk = nftsChunked_1[_i];
                nftMetadataPromises = chunk.map(function (nftInfo) { return __awaiter(void 0, void 0, void 0, function () {
                    var tokenInfo;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4, getTokenInfo(nftInfo.token_id)];
                            case 1:
                                tokenInfo = _a.sent();
                                if (nftInfo.metadata) {
                                    return [2, getMetadataObj(nftInfo.metadata).then(function (o) { return (__assign(__assign({}, nftInfo), { metadataObj: o, tokenInfo: tokenInfo })); }).catch(function (err) { return (__assign(__assign({}, nftInfo), { metadataErrObj: err, tokenInfo: tokenInfo })); })];
                                }
                                else {
                                    return [2, Promise.resolve(null)];
                                }
                                return [2];
                        }
                    });
                }); });
                return [4, Promise.all(nftMetadataPromises)];
            case 2:
                nftMetadatas = _a.sent();
                nftMetadatasFiltered = nftMetadatas.filter(function (o) { return o !== null; });
                allNfts = __spreadArray(__spreadArray([], allNfts, true), nftMetadatasFiltered, true);
                onChunkLoaded(nftMetadatasFiltered, allNfts);
                _a.label = 3;
            case 3:
                _i++;
                return [3, 1];
            case 4: return [2, allNfts];
        }
    });
}); };
export var listAllNftsWithMetadata = function (tokenId_1) {
    var args_1 = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args_1[_i - 1] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([tokenId_1], args_1, true), Promise, function (tokenId, maxRequests, onChunkLoaded) {
        var nfts;
        if (maxRequests === void 0) { maxRequests = 10; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, listAllNfts(tokenId, maxRequests)];
                case 1:
                    nfts = _a.sent();
                    return [4, loadMetadataForNfts(nfts, onChunkLoaded)];
                case 2: return [2, _a.sent()];
            }
        });
    });
};
export var listAllAccountNftsWithMetadata = function (accountId_1) {
    var args_1 = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args_1[_i - 1] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([accountId_1], args_1, true), Promise, function (accountId, maxRequests, onChunkLoaded) {
        var nfts;
        if (maxRequests === void 0) { maxRequests = 10; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, listAllNftsForAccount(accountId, maxRequests)];
                case 1:
                    nfts = _a.sent();
                    return [4, loadMetadataForNfts(nfts, onChunkLoaded)];
                case 2: return [2, _a.sent()];
            }
        });
    });
};
export var listTrans = function (onChunkLoaded) { return __awaiter(void 0, void 0, void 0, function () {
    var trans, params, _i, _a, t, response, processedResponse, skip, _b, _c, x, lastTrans, datetimestamp;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                trans = [];
                params = new URLSearchParams();
                params.append("limit", "100");
                params.append("timestamp", "gte:1654736400");
                params.append("order", Order.Asc);
                _i = 0, _a = [TransactionTypes.TOKENCREATION, TransactionTypes.TOKENASSOCIATE, TransactionTypes.TOKENMINT, TransactionTypes.CRYPTOTRANSFER];
                _e.label = 1;
            case 1:
                if (!(_i < _a.length)) return [3, 8];
                t = _a[_i];
                params.delete("transactiontype");
                params.append("transactiontype", t);
                return [4, fetch("".concat(client.baseUrl, "/api/v1/transactions?").concat(params.toString()))];
            case 2:
                response = _e.sent();
                return [4, client.processListTransactions(response)];
            case 3:
                processedResponse = _e.sent();
                if (processedResponse.result.transactions) {
                    trans.push.apply(trans, processedResponse.result.transactions);
                }
                skip = false;
                _e.label = 4;
            case 4:
                if (!(!skip && ((_d = processedResponse.result.links) === null || _d === void 0 ? void 0 : _d.next))) return [3, 7];
                _c = (_b = client).processListTransactions;
                return [4, fetch(client.baseUrl + processedResponse.result.links.next)];
            case 5: return [4, _c.apply(_b, [_e.sent()])];
            case 6:
                processedResponse = _e.sent();
                if (processedResponse.result.transactions && processedResponse.result.transactions.length > 0) {
                    trans = __spreadArray(__spreadArray([], trans, true), processedResponse.result.transactions, true);
                    x = 0;
                    lastTrans = trans[trans.length - 1];
                    if (lastTrans.valid_start_timestamp !== undefined) {
                        x = Number.parseFloat(lastTrans.valid_start_timestamp);
                    }
                    datetimestamp = new Date(x * 1000);
                    onChunkLoaded(processedResponse.result.transactions, trans);
                    if (datetimestamp.getDate() >= 15) {
                        skip = true;
                    }
                }
                if (skip) {
                    return [3, 4];
                }
                return [3, 4];
            case 7:
                _i++;
                return [3, 1];
            case 8: return [2, trans];
        }
    });
}); };
