import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import './App.scss';
import { Router } from './components/layout/Router';
import ReactGA from 'react-ga4';
ReactGA.initialize("G-W0EDGX3KYV");
ReactGA.send("pageview");
function App() {
    return (_jsx(_Fragment, { children: _jsx(Router, {}) }));
}
export default App;
