import { configureStore } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
var pageSlice = createSlice({
    name: "pageSlice",
    initialState: {
        isLoading: false,
        searchQuery: '',
    },
    reducers: {
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setSearchQuery: function (state, action) {
            state.searchQuery = action.payload.toLowerCase().trim();
        },
    }
});
var hashconnectSlice = createSlice({
    name: "hashconnectSlice",
    initialState: {
        isConnected: false,
        accountId: '',
    },
    reducers: {
        setIsConnected: function (state, action) {
            state.isConnected = action.payload;
        },
        setAccountId: function (state, action) {
            state.accountId = action.payload;
        },
    }
});
export var store = configureStore({
    reducer: {
        page: pageSlice.reducer,
        hashconnect: hashconnectSlice.reducer,
    }
});
export var actions = {
    page: pageSlice.actions,
    hashconnect: hashconnectSlice.actions
};
