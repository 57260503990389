var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CopyAll, GitHub, Send } from "@mui/icons-material";
import { Box, Button, IconButton, InputAdornment, List, ListItem, ListItemText, Modal, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { hc } from "./hashconnect-client";
import { Hbar, HbarUnit, TransferTransaction } from "@hashgraph/sdk";
import CoffeeIcon from '@mui/icons-material/Coffee';
import { SupportProgress } from "./support-progress";
var SupportModal = function (props) {
    var _a = useState(100), amount = _a[0], setAmount = _a[1];
    var isWalletConnected = useSelector(function (state) { return state.hashconnect.isConnected; });
    var pairedAccId = useSelector(function (state) { return state.hashconnect.accountId; });
    return (_jsx(Modal, { open: props.open, onClose: props.onClose, children: _jsxs(Box, { sx: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }, children: [_jsx(SupportProgress, { showAmount: true }), _jsx("br", {}), _jsx(Typography, { variant: "h6", children: "How to support NFT Explorer:" }), _jsxs(List, { disablePadding: true, children: [_jsx(ListItem, { disableGutters: true, disablePadding: true, children: _jsxs(ListItemText, { children: ["1. Send HBAR to ", _jsx("b", { children: "0.0.1005415" })] }) }), _jsx(ListItem, { disableGutters: true, disablePadding: true, children: _jsx(ListItemText, { children: "2. Send HBAR with HashPack" }) }), _jsx(ListItem, { disableGutters: true, disablePadding: true, children: _jsx(Stack, { width: "100%", children: isWalletConnected ? (_jsxs(_Fragment, { children: [_jsx(Box, { children: _jsx(TextField, { fullWidth: true, type: "number", value: amount, onChange: function (e) {
                                                    setAmount(Number.parseFloat(e.target.value));
                                                }, sx: {
                                                    marginBottom: 2,
                                                    textAlign: 'end',
                                                }, inputProps: {
                                                    sx: {
                                                        textAlign: 'end',
                                                    },
                                                }, InputProps: {
                                                    endAdornment: (_jsxs(_Fragment, { children: [_jsx(InputAdornment, { position: "end", children: "\u210F" }), _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                                        var devAccId, signer, trans;
                                                                        return __generator(this, function (_a) {
                                                                            switch (_a.label) {
                                                                                case 0:
                                                                                    devAccId = '0.0.1005415';
                                                                                    if (!devAccId) return [3, 3];
                                                                                    signer = hc.getSigner(hc.connectedAccountIds[0]);
                                                                                    return [4, new TransferTransaction()
                                                                                            .addHbarTransfer(pairedAccId, Hbar.from(-amount, HbarUnit.Hbar))
                                                                                            .addHbarTransfer(devAccId, Hbar.from(amount, HbarUnit.Hbar))
                                                                                            .setTransactionMemo('NFT Explorer support')
                                                                                            .freezeWithSigner(signer)];
                                                                                case 1:
                                                                                    trans = _a.sent();
                                                                                    return [4, trans.executeWithSigner(signer)];
                                                                                case 2:
                                                                                    _a.sent();
                                                                                    _a.label = 3;
                                                                                case 3: return [2];
                                                                            }
                                                                        });
                                                                    }); }, children: _jsx(Send, { color: "primary" }) }) })] })),
                                                } }) }), _jsxs(Button, { variant: "contained", onClick: function () {
                                                hc.disconnect();
                                            }, color: "error", children: ["Disconnect (", pairedAccId, ")"] })] })) : (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "contained", onClick: function () {
                                                hc.openPairingModal();
                                            }, sx: {
                                                marginBottom: 2
                                            }, children: "Connect" }), _jsx(TextField, { fullWidth: true, label: "Pairing String", variant: "outlined", value: hc.pairingString, InputProps: {
                                                endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { "aria-label": "Copy Pairing String", onClick: function () {
                                                            navigator.clipboard.writeText(hc.pairingString);
                                                        }, edge: "end", children: _jsx(CopyAll, {}) }) })),
                                            } })] })) }) })] }), _jsx(Typography, { children: "3. Contribute on GitHub" }), _jsx(IconButton, { "aria-label": "Open GitHub", edge: "end", href: "https://github.com/pwoosam/hedera-nft-explorer", rel: "noreferrer noopener", target: "_blank", children: _jsx(GitHub, {}) })] }) }));
};
export var SupportButton = function (props) {
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1];
    return (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ color: "success", variant: "contained", onClick: function () {
                    setIsModalOpen(!isModalOpen);
                } }, props, { children: _jsx(CoffeeIcon, {}) })), _jsx(SupportModal, { open: isModalOpen, onClose: function () {
                    setIsModalOpen(false);
                } })] }));
};
