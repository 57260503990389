import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Card, CardContent, Link, Chip, Popover, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { getMetadataObj, getNftInfo, getTokenInfo } from "../../api-clients/hedera-mirror-node-api-helper";
import { decodeBase64, wellKnownAccounts } from "../../utils";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import '@google/model-viewer';
import { IpfsMedia } from "./ipfs-media";
export var NftSquareDummy = function (props) {
    var onWidthChange = props.onWidthChange;
    var ref = useRef(null);
    var _a = useState(0), width = _a[0], setWidth = _a[1];
    useEffect(function () {
        if (ref.current) {
            var measure_1 = function () {
                if (ref.current) {
                    setWidth(ref.current.clientWidth);
                    onWidthChange(ref.current.clientWidth);
                }
            };
            measure_1();
            window.addEventListener("resize", measure_1);
            return function () {
                window.removeEventListener("resize", measure_1);
            };
        }
    }, [onWidthChange]);
    return (_jsxs(Card, { ref: ref, sx: {
            position: "relative",
            width: "100%",
            lineHeight: 0,
        }, children: [_jsx(Box, { width: width, height: width }), _jsxs(CardContent, { sx: {
                    lineHeight: 1,
                }, children: [_jsx(Typography, { variant: "h6", noWrap: true, children: "XXX" }), _jsx(Typography, { variant: "subtitle1", lineHeight: 1, pb: 1, children: "XXX" }), _jsx(Typography, { variant: "h6", children: "Owner" }), _jsx(Typography, { children: "XXX" }), _jsx(Typography, { variant: "h6", children: "Token" }), _jsx(Typography, { children: "XXX" })] })] }));
};
export var NftSquare = function (props) {
    var _a, _b;
    var serialNumberBadgeElm = useRef(null);
    var _c = useState(false), showAttributes = _c[0], setShowAttributes = _c[1];
    var metadataBadgeElm = useRef(null);
    var _d = useState(false), showMetadata = _d[0], setShowMetadata = _d[1];
    var _e = useState(false), showMoreDescription = _e[0], setShowMoreDescription = _e[1];
    var _f = useState(null), tokenInfo = _f[0], setTokenInfo = _f[1];
    var _g = useState(null), nftInfo = _g[0], setNftInfo = _g[1];
    var _h = useState(null), metadataObj = _h[0], setMetadataObj = _h[1];
    var _j = useState(null), metadataErrObj = _j[0], setMetadataErrObj = _j[1];
    var _k = useState(''), ipfsMediaErr = _k[0], setIpfsMediaErr = _k[1];
    useEffect(function () {
        if (props.tokenId) {
            getTokenInfo(props.tokenId).then(function (info) {
                setTokenInfo(info);
            });
        }
    }, [props.tokenId]);
    useEffect(function () {
        if (props.tokenId && props.serialNumber >= 0) {
            getNftInfo(props.tokenId, props.serialNumber).then(function (info) {
                setNftInfo(info);
            });
        }
    }, [props.tokenId, props.serialNumber]);
    useEffect(function () {
        if (nftInfo) {
            if (nftInfo.metadata) {
                setMetadataErrObj(null);
                getMetadataObj(nftInfo.metadata).then(function (o) {
                    setMetadataObj(o);
                }).catch(function (err) {
                    setMetadataErrObj(err);
                });
            }
            else {
                setMetadataErrObj(new Error('No metadata'));
            }
        }
    }, [nftInfo]);
    var metadataLoadingErrMessage = useMemo(function () {
        if (metadataErrObj) {
            if (typeof metadataErrObj.message === 'string') {
                return metadataErrObj.message;
            }
            else {
                return JSON.stringify(metadataErrObj, null, 2);
            }
        }
    }, [metadataErrObj]);
    var hasAnyKeys = useMemo(function () { return tokenInfo && (!!tokenInfo.admin_key ||
        !!tokenInfo.freeze_key ||
        !!tokenInfo.supply_key ||
        !!tokenInfo.pause_key ||
        !!tokenInfo.fee_schedule_key ||
        !!tokenInfo.kyc_key ||
        !!tokenInfo.wipe_key); }, [tokenInfo]);
    var shortDescriptionLength = 120;
    var _l = useMemo(function () {
        var description = '';
        if (metadataObj && metadataObj.description) {
            var metadataDesc = metadataObj.description;
            if (typeof metadataDesc === "string") {
                description = metadataDesc;
            }
            else if (metadataDesc.description) {
                description = metadataDesc.description;
            }
            else if (metadataDesc) {
                description = JSON.stringify(metadataDesc, null, 2);
            }
        }
        var isDescriptionLong = description.length > shortDescriptionLength;
        return [description, isDescriptionLong];
    }, [metadataObj]), description = _l[0], isDescriptionLong = _l[1];
    var ref = useRef(null);
    var _m = useState(0), width = _m[0], setWidth = _m[1];
    useEffect(function () {
        if (ref.current) {
            var measure_2 = function () {
                if (ref.current) {
                    setWidth(ref.current.clientWidth);
                }
            };
            measure_2();
            window.addEventListener("resize", measure_2);
            return function () {
                window.removeEventListener("resize", measure_2);
            };
        }
    }, [ref, tokenInfo, nftInfo, metadataObj]);
    if (!tokenInfo || !nftInfo || !(metadataObj || metadataLoadingErrMessage)) {
        return (_jsx(NftSquareDummy, { onWidthChange: function (width) {
                setWidth(width);
            } }));
    }
    var cannotLoadImg = tokenInfo.total_supply === "0" || !!ipfsMediaErr;
    return (_jsxs(Card, { ref: ref, sx: {
            position: "relative",
            width: "100%",
            lineHeight: 0,
        }, children: [(metadataLoadingErrMessage || cannotLoadImg) ? (_jsx(Box, { width: width, height: width, display: "flex", alignItems: "center", justifyContent: "center", children: _jsx(QuestionMarkIcon, { color: "primary", fontSize: "large" }) })) : (_jsx(IpfsMedia, { size: width, metadataObj: metadataObj, onError: function (err) {
                    setIpfsMediaErr(err);
                } })), _jsxs(CardContent, { sx: {
                    lineHeight: 1,
                }, children: [_jsx(Typography, { variant: "h6", title: tokenInfo.name, noWrap: true, children: tokenInfo.name }), _jsx(Typography, { variant: "subtitle1", lineHeight: 1, pb: 1, children: "".concat(props.serialNumber, "/").concat(tokenInfo.total_supply) }), nftInfo.spender === wellKnownAccounts["Zuse Secondary"] && (_jsx(Link, { href: "https://zuse.market/collection/".concat(tokenInfo.token_id), target: "_blank", referrerPolicy: "no-referrer", sx: {
                            "*": {
                                cursor: "pointer",
                            }
                        }, children: _jsx(Chip, { label: "Paperhanding on Zuse", color: "success" }) })), _jsx(Typography, { variant: "h6", children: "Owner" }), _jsx(Typography, { children: _jsx(Link, { to: "/account/".concat(nftInfo.account_id), component: RouterLink, children: nftInfo.account_id }) }), _jsx(Typography, { variant: "h6", children: "Token" }), _jsx(Typography, { children: _jsx(Link, { to: "/collection/".concat(nftInfo.token_id), component: RouterLink, children: nftInfo.token_id }) }), description && (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h6", children: "Description" }), _jsx(Typography, { variant: "body2", children: showMoreDescription || !isDescriptionLong ? description : description.substring(0, shortDescriptionLength) + '...' }), isDescriptionLong && (_jsx(Button, { type: "button", variant: "text", onClick: function () {
                                    setShowMoreDescription(!showMoreDescription);
                                }, children: showMoreDescription ? "Show less" : "Show more" }))] })), hasAnyKeys && (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "h6", children: "Keys" }), _jsxs(Box, { display: "flex", gap: 0.5, flexWrap: "wrap", children: [tokenInfo.supply_key && _jsx(Chip, { title: "".concat(tokenInfo.supply_key.key), label: "Supply", color: "success" }), tokenInfo.wipe_key && _jsx(Chip, { title: "".concat(tokenInfo.wipe_key.key), label: "Wipe", color: "error" }), tokenInfo.admin_key && _jsx(Chip, { title: "".concat(tokenInfo.admin_key.key), label: "Admin", color: "warning" }), tokenInfo.freeze_key && _jsx(Chip, { title: "".concat(tokenInfo.freeze_key.key), label: "Freeze", color: "warning" }), tokenInfo.pause_key && _jsx(Chip, { title: "".concat(tokenInfo.pause_key.key), label: "Pause", color: "warning" }), tokenInfo.fee_schedule_key && _jsx(Chip, { title: "".concat(tokenInfo.fee_schedule_key.key), label: "Fee Schedule", color: "warning" }), tokenInfo.kyc_key && _jsx(Chip, { title: "".concat(tokenInfo.kyc_key.key), label: "KYC", color: "warning" })] })] })), _jsx(Typography, { variant: "h6", children: "Actions" }), _jsxs(Box, { display: "flex", gap: 0.5, flexWrap: "wrap", children: [metadataObj && metadataObj.attributes && (_jsxs(_Fragment, { children: [_jsx(Chip, { label: "Show Attributes", ref: serialNumberBadgeElm, onClick: function () {
                                            setShowAttributes(!showAttributes);
                                        }, color: "primary" }), _jsx(Popover, { anchorEl: serialNumberBadgeElm.current, anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }, open: showAttributes, onClose: function () {
                                            setShowAttributes(false);
                                        }, children: metadataLoadingErrMessage ? (_jsx(Box, { p: 1, children: metadataLoadingErrMessage })) : (_jsx(Box, { display: "grid", gridTemplateColumns: "1fr 2fr", gap: 1, p: 1, children: metadataObj && ((_b = (_a = metadataObj.attributes) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (attr) { return (_jsxs(Fragment, { children: [_jsx(Box, { children: attr.trait_type }), _jsx(Box, { children: attr.value })] }, attr.trait_type)); })) })) })] })), _jsx(Chip, { label: "Show Raw Metadata", ref: metadataBadgeElm, onClick: function () {
                                    setShowMetadata(!showMetadata);
                                }, color: "primary" }), _jsx(Popover, { anchorEl: metadataBadgeElm.current, anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }, open: showMetadata, onClose: function () {
                                    setShowMetadata(false);
                                }, children: _jsx(Box, { px: 1, maxWidth: "70vw", children: _jsxs("pre", { className: "pre-wrap", children: [(nftInfo === null || nftInfo === void 0 ? void 0 : nftInfo.metadata) ? (_jsxs(_Fragment, { children: [nftInfo === null || nftInfo === void 0 ? void 0 : nftInfo.metadata, _jsx("br", {}), _jsx("br", {}), decodeBase64(nftInfo === null || nftInfo === void 0 ? void 0 : nftInfo.metadata), _jsx("br", {}), _jsx("br", {})] })) : null, metadataLoadingErrMessage ? (metadataLoadingErrMessage) : (JSON.stringify(metadataObj, null, 2))] }) }) })] })] })] }));
};
