var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { fromIpfsIdToUrl, fromIpfsProtocolToUrl } from "../../api-clients/hedera-mirror-node-api-helper";
var isVideoMetadata = function (metadataObj) {
    if ((metadataObj === null || metadataObj === void 0 ? void 0 : metadataObj.type) && typeof metadataObj.type === 'string') {
        return metadataObj.type.startsWith('video');
    }
    return false;
};
var getUrlFromImageMetadata = function (metadataObj) {
    if (!metadataObj) {
        return '';
    }
    if (!metadataObj.image && !metadataObj.CID) {
        return '';
    }
    if (typeof metadataObj.image === 'object') {
        return getUrlFromImageMetadata(__assign(__assign({}, metadataObj), { image: metadataObj.image.description }));
    }
    var isVideo = isVideoMetadata(metadataObj);
    var srcUrl = '';
    if (metadataObj.image) {
        if (metadataObj.image.startsWith("ipfs://")) {
            srcUrl = fromIpfsProtocolToUrl(metadataObj.image);
        }
        else if (metadataObj.image.startsWith("http")) {
            var url = new URL(metadataObj.image);
            var cid = '';
            if (url.pathname.startsWith('/ipfs')) {
                cid = url.pathname.replace('/ipfs/', '');
            }
            else {
                cid = url.hostname.split('.')[0];
            }
            srcUrl = fromIpfsIdToUrl(cid);
        }
        else if (metadataObj.image) {
            srcUrl = fromIpfsIdToUrl(metadataObj.image);
        }
    }
    else if (metadataObj.CID) {
        var cid = metadataObj.CID.replace('https://', '').split('.')[0];
        srcUrl = fromIpfsIdToUrl(cid);
    }
    else if (metadataObj.CID) {
        srcUrl = fromIpfsIdToUrl(metadataObj.CID);
    }
    if (!isVideo) {
        srcUrl += '?class=thumbnail';
    }
    return srcUrl;
};
export var IpfsMedia = function (props) {
    var metadataObj = props.metadataObj, size = props.size, onError = props.onError;
    var isVideo = useMemo(function () { return isVideoMetadata(metadataObj); }, [metadataObj]);
    var srcUrl = useMemo(function () { return getUrlFromImageMetadata(metadataObj); }, [metadataObj]);
    return (_jsx(_Fragment, { children: isVideo ? (_jsx("video", { width: size, height: size, src: srcUrl, autoPlay: true, playsInline: true, loop: true, muted: true, className: "img-contain", onError: function () {
                onError('Video failed to load');
            } })) : (srcUrl.includes('.glb') ? (_jsx(Box, { width: size, height: size, px: 2, sx: {
                marginTop: "auto",
                marginBottom: "auto",
                justifyContent: "center",
            }, display: "flex", alignItems: "center", children: _jsx(Typography, { variant: "h5", children: ".gbl not supported" }) })) : (_jsx("img", { width: size, height: size, alt: "NFT Thumbnail", src: srcUrl, className: "img-contain", loading: "lazy", onError: function () {
                onError('Image failed to load');
            } }))) }));
};
