import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { Account } from "../../pages/Account";
import { Collection } from "../../pages/Collection";
import { Home } from "../../pages/Home";
import { Layout } from "./Layout";
import { Navbar } from "./Navbar";
export var Router = function () {
    return (_jsxs(BrowserRouter, { children: [_jsx(Navbar, {}), _jsx(Routes, { children: _jsxs(Route, { path: "/", element: _jsx(Layout, {}), children: [_jsx(Route, { index: true, element: _jsx(Home, {}) }), _jsx(Route, { path: ":id", element: _jsx(Collection, {}) }), _jsx(Route, { path: "collection/:id", element: _jsx(Collection, {}) }), _jsx(Route, { path: "account/:id", element: _jsx(Account, {}) })] }) })] }));
};
