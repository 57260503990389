import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import CssBaseline from '@mui/material/CssBaseline';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './theme';
import { store } from './store';
import { Buffer } from 'buffer';
import { HashConnectClient } from './components/hashconnect/hashconnect-client';
window.Buffer = window.Buffer || Buffer;
var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(React.StrictMode, { children: _jsxs(Provider, { store: store, children: [_jsx(HashConnectClient, {}), _jsxs(ThemeProvider, { children: [_jsx(CssBaseline, {}), _jsx(App, {})] })] }) }));
reportWebVitals();
