var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { getThisMonthsIncome } from "../../api-clients/hedera-mirror-node-api-helper";
var p = -1;
var goal = 3000;
export var SupportProgress = function (props) {
    var _a = useState(p), amount = _a[0], setAmount = _a[1];
    var progress = useMemo(function () {
        var percentOfGoal = amount / goal * 100;
        var percentFormatted = Number.parseFloat(percentOfGoal.toFixed(0));
        return percentFormatted;
    }, [amount]);
    var updateAmount = useMemo(function () {
        return function () { return __awaiter(void 0, void 0, void 0, function () {
            var accId, transactions, incomeAmounts, accumulatorInTinybars, _i, incomeAmounts_1, incomeAmount, hbarsNum;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accId = '0.0.1005415';
                        return [4, getThisMonthsIncome(accId)];
                    case 1:
                        transactions = _a.sent();
                        incomeAmounts = transactions.flatMap(function (t) { var _a; return (_a = t.transfers) === null || _a === void 0 ? void 0 : _a.filter(function (ts) { return ts.account === accId && ts.amount > 0; }).map(function (o) { return o.amount; }); });
                        accumulatorInTinybars = 0;
                        for (_i = 0, incomeAmounts_1 = incomeAmounts; _i < incomeAmounts_1.length; _i++) {
                            incomeAmount = incomeAmounts_1[_i];
                            if (incomeAmount !== undefined) {
                                accumulatorInTinybars += incomeAmount;
                            }
                        }
                        hbarsNum = accumulatorInTinybars / 100000000;
                        setAmount(hbarsNum);
                        p = hbarsNum;
                        return [2];
                }
            });
        }); };
    }, []);
    useEffect(function () {
        updateAmount();
    }, [updateAmount]);
    useEffect(function () {
        var handle = setInterval(function () {
            updateAmount();
        }, 120 * 1000);
        return function () { return clearInterval(handle); };
    }, [updateAmount]);
    var color = useMemo(function () {
        if (progress < 40) {
            return 'error';
        }
        else if (progress < 90) {
            return 'warning';
        }
        else {
            return 'success';
        }
    }, [progress]);
    return (_jsxs(Box, { display: "flex", flexDirection: "column", children: [_jsx(Typography, { variant: "subtitle2", color: color, lineHeight: 1, mx: "auto", children: props.showAmount ? ("".concat(amount.toLocaleString(), "\u210F / ").concat(goal, "\u210F")) : ("".concat(progress, "% \u2615")) }), _jsx(LinearProgress, { variant: "determinate", color: color, value: progress > 100 ? 100 : progress })] }));
};
