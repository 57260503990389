var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Box, Checkbox, Chip, CircularProgress, FormControlLabel, Grid, Pagination, Snackbar, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { listAllNftsForAccount } from "../api-clients/hedera-mirror-node-api-helper";
import { AccountBalance } from "../components/accounts/account-balance";
import { NftSquare } from "../components/nfts/nft-square";
import { getDomainsForAccount } from "../services/domain-service";
import { actions } from "../store";
import { wellKnownAccounts } from "../utils";
export var Account = function () {
    var _a = useState([]), nfts = _a[0], setNfts = _a[1];
    var _b = useState([]), accountDomains = _b[0], setAccountDomains = _b[1];
    var _c = useState(false), paperhandsOnly = _c[0], setPaperhandsOnly = _c[1];
    var _d = useState(false), loadingDomains = _d[0], setLoadingDomains = _d[1];
    var _e = useState(''), err = _e[0], setErr = _e[1];
    var dispatch = useDispatch();
    var params = useParams();
    var id = params.id ? params.id : "0.0.994239";
    var idRef = useRef(id);
    useEffect(function () {
        idRef.current = id;
    }, [id]);
    useEffect(function () {
        setNfts([]);
        dispatch(actions.page.setIsLoading(true));
        listAllNftsForAccount(id, Infinity).then(function (allNfts) {
            if (allNfts.length === 0) {
                setErr("Could not find NFTs for account id: ".concat(id));
                setNfts([]);
            }
            else {
                setNfts(allNfts);
            }
        }).catch(function (err) {
            if (idRef.current === id) {
                setErr(typeof err === 'string' ? err : JSON.stringify(err));
            }
        }).finally(function () {
            if (idRef.current === id) {
                dispatch(actions.page.setIsLoading(false));
            }
        });
    }, [id, idRef, dispatch]);
    useEffect(function () {
        setLoadingDomains(true);
        getDomainsForAccount(id).then(function (domains) {
            var domainsWithTld = domains.sort().map(function (d) { return ({
                domain: d,
                tld: d.split('.').reverse()[0]
            }); });
            var accountDomainsTemp = [];
            var _loop_1 = function (domainWithTld) {
                var index = accountDomainsTemp.findIndex(function (o) { return o.tld === domainWithTld.tld; });
                if (index === -1) {
                    accountDomainsTemp.push({
                        tld: domainWithTld.tld,
                        domains: [domainWithTld.domain]
                    });
                }
                else {
                    accountDomainsTemp[index].domains.push(domainWithTld.domain);
                }
            };
            for (var _i = 0, domainsWithTld_1 = domainsWithTld; _i < domainsWithTld_1.length; _i++) {
                var domainWithTld = domainsWithTld_1[_i];
                _loop_1(domainWithTld);
            }
            setAccountDomains(accountDomainsTemp.sort(function (a, b) { return a.tld.localeCompare(b.tld); }));
        }).finally(function () {
            setLoadingDomains(false);
        });
    }, [id]);
    var nftsFiltered = useMemo(function () {
        var nftsFiltered_ = __spreadArray([], nfts, true);
        if (paperhandsOnly) {
            nftsFiltered_ = nftsFiltered_.filter(function (o) { return o.spender === wellKnownAccounts["Zuse Secondary"]; });
        }
        return nftsFiltered_;
    }, [nfts, paperhandsOnly]);
    var itemsPerPage = 12;
    var pages = Math.ceil(nftsFiltered.length / itemsPerPage);
    var _f = useState(1), currentPage = _f[0], setCurrentPage = _f[1];
    var _g = useState('1'), currentPageInput = _g[0], setCurrentPageInput = _g[1];
    var setCurrentPageDebounced = useMemo(function () {
        return _.debounce(function (pageNum) { return setCurrentPage(pageNum); }, 250, {
            leading: false,
            trailing: true,
        });
    }, [setCurrentPage]);
    useEffect(function () {
        var num = Number.parseInt(currentPageInput);
        if (Number.isNaN(num)) {
            return;
        }
        if (num > pages) {
            setCurrentPageDebounced(pages);
        }
        else if (num < 1) {
            setCurrentPageDebounced(1);
        }
        else if (!num) {
            setCurrentPageDebounced(1);
        }
        else {
            setCurrentPageDebounced(num);
        }
    }, [pages, currentPageInput, setCurrentPageDebounced]);
    var startIndex = itemsPerPage * (currentPage - 1);
    var endIndex = startIndex + itemsPerPage;
    return (_jsxs(Stack, { spacing: 1, children: [_jsx(Snackbar, { open: err !== '', onClose: function () { return setErr(''); }, children: _jsx(Alert, { severity: "error", onClose: function () { return setErr(''); }, children: err }) }), _jsx(AccountBalance, { accountId: id }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h2", children: "Domains" }), loadingDomains && _jsx(CircularProgress, { color: "primary" }), (!loadingDomains && accountDomains.length > 0) && (accountDomains.map(function (domainsPerTld) {
                        return (_jsxs(Fragment, { children: [_jsxs(Typography, { variant: "h6", children: [".", domainsPerTld.tld] }), _jsx(Box, { display: "flex", gap: 0.5, flexWrap: "wrap", children: domainsPerTld.domains.map(function (domain) {
                                        return (_jsx(Chip, { title: domain, label: domain }, domain));
                                    }) })] }, domainsPerTld.tld));
                    })), (!loadingDomains && accountDomains.length === 0) && (_jsx(Box, { children: "None" }))] }), _jsxs(Box, { display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "1rem", children: [_jsx(Typography, { variant: "h2", children: "NFTs" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: paperhandsOnly, onChange: function (e) {
                                setPaperhandsOnly(e.target.checked);
                            } }), label: "Paperhands Only" })] }), _jsx(Box, { children: _jsx(Grid, { container: true, spacing: 1, children: nftsFiltered.slice(startIndex, endIndex).map(function (o) {
                        return (_jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, lg: 2, children: _jsx(NftSquare, { tokenId: o.token_id, serialNumber: o.serial_number }) }, "".concat(o.token_id, ":").concat(o.serial_number)));
                    }) }) }), _jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "center", children: [_jsx(Pagination, { count: pages, page: currentPage, color: "primary", onChange: function (_, page) {
                            setCurrentPage(page);
                            setCurrentPageInput("".concat(page));
                        } }), _jsx(TextField, { type: "number", value: currentPageInput, onChange: function (e) {
                            setCurrentPageInput(e.target.value);
                        }, inputProps: {
                            sx: {
                                paddingTop: '0.5rem',
                                paddingBottom: '0.5rem',
                            },
                        }, sx: {
                            width: '5rem',
                        } })] })] }));
};
